import { withTranslation, WithTranslation } from "react-i18next";
import React, { ReactNode } from "react";
import { TFunction } from "i18next";
import { FormService } from "../services/FormService";
import { Checkbox } from "@bbri/ui";
import { FormQuestion } from "../models/FormQuestion";

interface Props extends WithTranslation {
  question: FormQuestion;
  currentValues: string[];
  values: any[];
  onValueChange: (v: any) => void;
  name: string;
}

class FormCheckbox extends React.Component<Props> {
  private t: TFunction;
  private formService: FormService;
  private valueList: string[] = [];

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;
  }

  onValueChange(v: any) {
    this.setState({ ...this.state, value: v });
  }

  render() {
    const list: ReactNode[] = [];
    const values = this.props.currentValues;
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      const key = `${this.props.name}${i}`;
      list.push(
        <a key={`a_${key}`}>
          <Checkbox
            id={key}
            key={key}
            label={this.formService.t(this.t, value, true)}
            checked={(this.props.values && this.props.values.includes(value)) ? true : null}
            onChange={() => {
              this.props.onValueChange(value);
            }}
          />
        </a>
      );
    }

    return list;
  }
}

export default withTranslation()(FormCheckbox);
