import { Select } from "@bbri/ui";
import { TFunction } from "i18next";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { RichText } from "prismic-reactjs";
import { FormService } from "../services/FormService";

interface Props extends WithTranslation {
  name: string;
  currentValue: string;
  values: string[];
  onValueChange: (v: string) => void;
  disabled?: boolean;
  isSubQuestion?: boolean;
}

class FormDropdown extends Component<Props> {
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.t = props.t;
    this.formService = new FormService();
  }

  render() {
    let options: { id: string; name: string }[] = [];

    for (const value of this.props.values) {
      options.push({
        id: value,
        // @ts-ignore
        name: RichText.asText(this.t(value, { returnObjects: true })),
      });
    }

    for (const option of options) {
      if (!option.name) {
        option.name = option.id;
      }
    }

    return (
      <div style={this.props.isSubQuestion ? { maxWidth: '480px' } : {}}>
        <Select
          selection={this.props.currentValue}
          options={options}
          displayProperty="name"
          valueProperty="id"
          placeholder={this.formService.checkIfValue(RichText.render(this.t("ui_placeholder_select_value", { returnObjects: true })), "ui_placeholder_select_value")}
          onChange={(v: { id: string; name: string }) => {
            if (v.id !== this.props.currentValue) {
              this.props.onValueChange(v.id);
            }
          }}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export default withTranslation()(FormDropdown);
