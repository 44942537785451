import { AxiosResponse } from 'axios';
import { APIService } from '../../shared/services/APIService';
import {Html} from "../models/HTML";
import {HtmlDTO} from "../dto/HtmlDTO";

export class AdminHtmlsService extends APIService {

    /**
     * Get many htmls
     */
    getMany(): Promise<AxiosResponse<Html[]>> {
        return this.http.get<Html[]>('/htmls');
    }

    /**
     * Get the text of a html by name
     */
     getOne(name: string): Promise<AxiosResponse<string>> {
        return this.http.get<string>(`/htmls/${name}/text`);
    }
    
    /**
     * Download a html by name
     */
    downloadOne(name: string) {
        return this.http.get<Blob>(`/htmls/${name}/download`, { responseType: 'blob' });
    }
  
  
    /**
     * Upload many htmls
     */
    uploadMany(files: File[]) {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file);
        }
        return this.http.post<void>(`/htmls/upload`, formData);
    }

    /**
     * Upload one html
     */
    uploadOne(dto: HtmlDTO) {
        return this.http.post<void>(`/htmls/uploadone`, dto);
    } 

    /**
     * Delete one html
     */
    deleteOne(name: string) {
        return this.http.delete<void>(`/htmls/${name}`);
    }
}
