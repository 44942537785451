import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { TFunction } from "i18next";
import { FormService } from "../../../../form/services/FormService";
import { Toast } from "primereact/toast";
import "../CodeSidebar.scss";
import { Button } from "primereact/components/button/Button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Column } from "primereact/components/column/Column";

import { AxiosResponse } from "axios";
import { FileUtils } from "../../../../shared/utils/FileUtils";
import { SeverityEnum } from "../../../../shared/enum/SeverityEnum";
import { Toolbar } from "primereact/toolbar";
import { FileUpload } from "primereact/fileupload";
import { Html } from "../../../models/HTML";
import { AdminHtmlsService } from "../../../services/AdminHTMLService";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";

interface States {
  htmls: Html[];
  isScriptsRefreshing: boolean;
  displayConfirmation: boolean;
  toDelete: string | null;
  loading: boolean;
  selectedScript: any;
  displayNewScriptConfirmation: boolean;
  menuFilter: string;
}

interface Props extends WithTranslation {
  scriptToViewCallback: any;
  deletedScript: any;
  reloadScripts: boolean;
  resetRefreshScriptsState: any;
  actionDeleteHtml: boolean;
  actionDownloadHtml: boolean;
}

class CodeSidebarHTML extends React.Component<Props, States> {

  private t: TFunction;
  private formService: FormService;
  private toast: Toast | null;
  private fileUpload: FileUpload | null;
  private readonly htmlService: AdminHtmlsService;
  private scripts: any = [];

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.htmlService = new AdminHtmlsService();
    this.t = this.props.t;
    this.toast = null;
    this.fileUpload = null;

    this.state = {
      htmls: [],
      isScriptsRefreshing: false,
      displayConfirmation: false,
      toDelete: null,
      loading: false,
      selectedScript: null,
      displayNewScriptConfirmation: false,
      menuFilter: '',
    };
  }


  componentDidMount() {
    this.getHtmls();
  }

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    if (nextProps.reloadScripts) {
      this.setState({
        isScriptsRefreshing: true
      });
      this.getHtmls();

      this.props.resetRefreshScriptsState();
    }
    if (nextProps.actionDownloadHtml) {
      this.handleDownload();
    }
    if (nextProps.actionDeleteHtml) {
      if (!this.state.selectedScript) {
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `Please select a script !`,
        });
        return;
      }
      else {
        this.setState({ displayConfirmation: true, toDelete: this.state.selectedScript.fileName });
      }
    }
  }


  /**
   * On download
   */
  handleDownload() {

    if (!this.state.selectedScript) {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: `Please select a script !`,
      });
      return;
    }

    this.htmlService
      .downloadOne(this.state.selectedScript.fileName)
      .then((response: AxiosResponse<Blob>) => {
        FileUtils.downloadFile(response);
      });
  }

  /**
   * On upload
   */
  handleUpload(e: { files: File[] }) {
    if (!e.files) {
      return;
    }

    this.htmlService
      .uploadMany(e.files)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          /* detail: this.formService.t(this.t, "ui_admin_html_upload_toast_success", false), */
          detail: `Success - File uploaded`,
        });
        this.fileUpload?.clear();
        this.getHtmls();
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `File NOT uploaded : ${message}`,
          /* detail: `${this.formService.t(this.t, "ui_admin_html_upload_toast_error", false)} : ${message}`, */
        });
      });
  }

  /**
   * New script
   */
  handleNewScript() {
    this.setState({ displayNewScriptConfirmation: false });
    this.resetEditorText();
  }

  /**
   * Delete script
   */
  handleDelete() {
    if (!this.state.toDelete) {
      return;
    }
    this.htmlService.deleteOne(this.state.toDelete).then(() => {
      this.toast?.show({
        severity: SeverityEnum.SUCCESS,
        detail: this.t("ui_admin_html_delete_toast_success"),
      });
      this.setState({ displayConfirmation: false, toDelete: null, selectedScript: null });
      this.getHtmls();
      this.resetEditorText();
    });
  }


  resetEditorText() {
    this.props.deletedScript();
  }

  /**
   * Load html
   */
  getHtmls() {
    this.htmlService
      .getMany()
      .then((response: AxiosResponse<Html[]>) => {
        this.scripts = response.data;
        this.setState({ htmls: response.data });
      });
  }

  actionBodyTemplate() {
    return (
      <React.Fragment>
        <Button
          type="button"
          /* label="New script"  */
          icon="pi pi-plus"
          className="p-button-success"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
          tooltip="Create new requirement"
          onClick={() =>
            this.setState({ displayNewScriptConfirmation: true })
            //this.props.scriptToViewCallback(this.state.selectedScript.fileName);

          }
        />
        <FileUpload
          chooseLabel="Upload"
          uploadLabel="Ready to upload"
          mode="basic"
          ref={(el) => (this.fileUpload = el)}
          accept=".html"
          customUpload={true}
          /* multiple={true} */
          uploadHandler={(e: { files: File[] }) => this.handleUpload(e)}
          auto
          // emptyTemplate={() => <p className="p-m-0">{this.t("ui_admin_html_upload_content")}</p>}
        />
      </React.Fragment>
    );
  }

  renderDialogFooter() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ displayConfirmation: false })}
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          className="p-button-danger"
        />
        <Button
          label="Yes"
          icon="pi pi-check" style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
          className="p-button-danger"
          onClick={() => this.handleDelete()}
          autoFocus
        />
      </div>
    );
  }

  /*
    Validate methods
  */
  validationWindowForNewScript() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ displayNewScriptConfirmation: false })}
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          className="p-button-danger"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.handleNewScript()}
          autoFocus
          className="p-button-danger"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
        />
      </div>
    );
  }

  private _rowClass(data) {
    return {
      'row-active': data.isActive === true
    };
  }

  render() {
    return (
      <>
        <Toast ref={(el) => this.toast = el}/>
        <InputText
          placeholder="Filter by name"
          className="menuFilter"
          value={this.state.menuFilter}
          onChange={(e: any) => {
            this.setState({
              menuFilter: e.target.value
            });
            if (e.target.value) {
              const scripts = this.scripts;
              const scriptsFiltered: any = [];
              for (const script of scripts) {
                if (script.fileName.includes(e.target.value)) {
                  scriptsFiltered.push(script);
                }
              }
              this.setState({
                htmls: scriptsFiltered
              });
            }
            else {
              this.setState({
                htmls: this.scripts
              });
            }
          }}
        />
        <DataTable
          value={this.state.htmls}
          tableClassName="table-custom"
          scrollable
          scrollHeight="40vh"
          selection={this.state.selectedScript}
          onSelectionChange={e => this.setState({ selectedScript: e.value }, () => {
            this.props.scriptToViewCallback(this.state.selectedScript.fileName);
          })}
          dataKey="fileName"
          stateStorage="local"
          selectionMode="single"
          rowClassName={this._rowClass}
        >
          <Column
            field="fileName"
            header="List of templates"
          />
        </DataTable>
        <div>
          <Message severity="warn" text="The default requirement table will be displayed if there is no active Html template !"/>
        </div>
        <Toolbar
          left={() =>
            this.actionBodyTemplate()
          }
        />
        <Dialog
          header="Confirmation"
          visible={this.state.displayConfirmation}
          modal
          style={{ width: "350px" }}
          footer={this.renderDialogFooter()}
          onHide={() => this.setState({ displayConfirmation: false })}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>Are you sure you want to proceed?</span>
          </div>
        </Dialog>


        <div>
          <Dialog
            header="Confirmation"
            visible={this.state.displayNewScriptConfirmation}
            modal
            style={{ width: "350px" }}
            footer={this.validationWindowForNewScript()}
            onHide={() => this.setState({ displayNewScriptConfirmation: false })}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              <span>Are you sure you want to create a new script?</span>

            </div>
          </Dialog>
        </div>
      </>
    );
  }
}

export default withTranslation()(CodeSidebarHTML);
