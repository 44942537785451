import { AxiosResponse } from 'axios';
import { APIService } from '../../shared/services/APIService';
import { DecisionTree } from '../models/DecisionTree';

export class AdminDecisionTreeService extends APIService {

  /**
   * Get many decision trees
   */
  getMany(): Promise<AxiosResponse<DecisionTree[]>> {
    return this.http.get<DecisionTree[]>('/decision-trees');
  }

  /**
   * Download a decision tree by name
   */
  downloadOne(name: string) {
    return this.http.get<Blob>(`/decision-trees/${name}/download`, { responseType: 'blob' });
  }

  /**
   * Upload many decision trees
   */
  uploadMany(files: File[]) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    return this.http.post<void>(`/decision-trees/upload`, formData);
  }

  /**
   * Delete one decision tree
   */
  deleteOne(name: string) {
    return this.http.delete<void>(`/decision-trees/${name}`);
  }
}