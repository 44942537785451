import draw2d from "draw2d";
import {Transition} from "../shapes/transition";
const draw2d2 = draw2d as any;

export class ConnectionCreatePolicy extends draw2d2.policy.connection.DragConnectionCreatePolicy {
    NAME = 'ConnectionCreatePolicy';
    canvas: any;

    constructor(canvas?: any) {
        super();
        this.canvas = canvas;
    }

    createConnection(){
        return new Transition();
    }
}
