import {TFunction} from 'i18next';
import {QuestionType} from '../enum/QuestionType';
import {Form} from '../models/Form';
import {FormQuestion} from '../models/FormQuestion';
import {OperatorType} from "./../enum/OperatorType";
import {DecisionTreeService} from './../services/DecisionTreeService';
import {FormService} from "../services/FormService";

export class FormUtils {

  static formatValue(
    t: TFunction,
    formService: FormService,
    form: Form,
    history: number[],
    values: Record<string, any>,
    callback: (id: number, question: FormQuestion, valueFormatted: string) => void
  ) {
    for (let i = 0; i < history.length; i++) {
      const id = history[i];
      const question = form.questions[id];
      if(question.type === QuestionType.MULTIPLE && question.subQuestions) {
        for(const subQ of question.subQuestions) {
          let value = values[subQ.question];
          if(value === undefined) {
            value = "";
          } else {
            switch (subQ.type) {
              case QuestionType.RADIO:
              case QuestionType.MATRIX:
              case QuestionType.RADIO_IMAGE:
                break;
              case QuestionType.NUMBER:
                value = value + " " +  formService.t(t, subQ.unit ?? '', false);
                break;
              case QuestionType.BOOL:
                value = value ? formService.t(t, "oui", false) : formService.t(t, "non", false);
                break;
            }
          }
          callback(id, subQ, value);
        }
      } else {
        let value = values[question.question];
        if(value === undefined) {
          value = "";
        } else {
          switch (question.type) {
            case QuestionType.RADIO:
            case QuestionType.MATRIX:
            case QuestionType.RADIO_IMAGE:
              break;
            case QuestionType.NUMBER:
              value = value + " " + formService.t(t, question.unit ?? '', false);
              break;
            case QuestionType.BOOL:
              value = value ? formService.t(t, "oui", false) : formService.t(t, "non", false);
              break;
          }
        }

        callback(id, question, value);
      }
    }
  }

  /**
   * Get the values to send to the back based on the currentQuestion
   * @return Record<string, any>
   */
  static getValidValues(questions: FormQuestion[], allValues: Record<string, any>, questionHistory: number[], currentQuestion: number, allowNextQuestion?: boolean) {
    const values: Record<string, any> = {};
    const history = [...questionHistory];

    // Add currentQuestion to history if not present
    if (!history.includes(currentQuestion)) {
      history.push(currentQuestion);
    }

    let i = 0;
    while (i < history.length) {
      const questionId = history[i];

      i++;

      // Don't use the question if it's after the current question
      if (questionId > currentQuestion && !allowNextQuestion) {
        continue;
      }

      const question = questions[questionId];

      // Continue if question was not found
      if (!question || !question?.question) {
        continue;
      }

      const value = allValues[question.question];

      // Continue if the value of the question is undefined. Otherwise decision trees might have an issue
      if (value === undefined) {
        continue;
      }

      switch (question.type) {
        case QuestionType.MULTIPLE:
          for (const key in value) {
            if (!value.hasOwnProperty(key)) {
              continue;
            }
            values[key] = value[key];
          }
          break;
        default:
          values[question.question] = value;
          break;
      }
    }

    return values;
  }

  /**
   * Check condition for a question
   */
  static async checkCondition(
    decisionTreeService: DecisionTreeService,
    leftValue: any,
    operator: OperatorType,
    rightValue: any,
    values: any,
    cache: Map<string, any>
  ): Promise<boolean> {
    if (leftValue.question) {
      leftValue = values[leftValue.question];
    } else if (leftValue.decision_tree) {

      if (cache.has(leftValue.decision_tree)) {
        leftValue = cache.get(leftValue.decision_tree);
      } else {
        const decisionTree = leftValue.decision_tree;
        leftValue = await decisionTreeService.computeDecisionTree(
          leftValue.decision_tree,
          values
        );
        cache.set(decisionTree, leftValue);
      }
    }

    switch (operator) {
      case OperatorType.EQUAL:
        return leftValue === rightValue;
      case OperatorType.NOT_EQUAL:
        return leftValue !== rightValue;
      case OperatorType.AND:
        return (
          (await FormUtils.checkCondition(
            decisionTreeService,
            leftValue.lhs,
            leftValue.operator,
            leftValue.rhs,
            values,
            cache
          )) &&
          (await FormUtils.checkCondition(
            decisionTreeService,
            rightValue.lhs,
            rightValue.operator,
            rightValue.rhs,
            values,
            cache
          ))
        );
      case OperatorType.OR:
        return (
          (await FormUtils.checkCondition(
            decisionTreeService,
            leftValue.lhs,
            leftValue.operator,
            leftValue.rhs,
            values,
            cache
          )) ||
          (await FormUtils.checkCondition(
            decisionTreeService,
            rightValue.lhs,
            rightValue.operator,
            rightValue.rhs,
            values,
            cache
          ))
        );
      case OperatorType.GREATER_THAN:
        return leftValue > rightValue;
      case OperatorType.GREATER_THAN_OR_EQUAL_TO:
        return leftValue >= rightValue;
      case OperatorType.LOWER_THAN:
        return leftValue < rightValue;
      case OperatorType.LOWER_THAN_OR_EQUAL_TO:
        return leftValue <= rightValue;
      case OperatorType.IN:
        if (Array.isArray(rightValue) && rightValue) {
          return (rightValue as any[]).includes(leftValue);
        } else if (Array.isArray(leftValue) && leftValue) {
          return (leftValue as any[]).includes(rightValue);
        } else {
          return false;
        }
      case OperatorType.NOT_IN:
        if (Array.isArray(rightValue) && rightValue) {
          return !(rightValue as any[]).includes(leftValue);
        } else if (Array.isArray(leftValue) && leftValue) {
          return !(leftValue as any[]).includes(rightValue);
        } else {
          return true;
        }
      case OperatorType.STRICTLY_EQUAL:
        if(Array.isArray(leftValue) && leftValue) {
          return JSON.stringify(leftValue.sort()) === JSON.stringify(rightValue.sort());
        } else {
          return false;
        }
      case OperatorType.STRICTLY_INCLUDED:
        if (Array.isArray(leftValue) && leftValue) {
          return (leftValue as any[]).every(elem => {
            return rightValue.includes(elem)
          });
        } else {
          return false;
        }
      case OperatorType.STRICTLY_INCLUDES:
        if (Array.isArray(leftValue) && leftValue) {
          return (rightValue as any[]).every(elem => {
            return leftValue.includes(elem)
          });
        } else {
          return false;
        }
    }
    return false;
  }

}
