import { Button } from "@bbri/ui";
import React, { useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { AuthService } from "../services/AuthService";
import { LoginDTO } from "../dto/LoginDTO";
import { AuthUser } from "../models/AuthUser";
import { FormService } from '../../form/services/FormService';
import ReactGA from "react-ga";
import { ReactGAUtils } from "../../shared/utils/ReactGAUtils";
import { Send } from "react-feather";

interface Props extends WithTranslation {
  onLogin: (isAuth: boolean) => void;
}

function ButtonAnonymous(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const authService = new AuthService();
  const formService = new FormService();

  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    const dto = new LoginDTO('anonymous@bbri.be', '');

    setClicked(true);

    authService.login(dto).then((user: AuthUser | null) => {
      if (!user) {
        props.onLogin(false);
        return;
      }

      ReactGA.event({
        category: ReactGAUtils.getCategory(),
        action: 'click',
        label: 'Démarrage'
      });

      props.onLogin(true);

      let from = { pathname: "/" };

      if (location.state) {
        from = (location.state as any).from;
      }

      history.replace(from);
    });
  };

  return (
    <Button block type="button" id="start" onClick={ () => {
      handleClick();
    } } disabled={clicked}>
      { formService.t(props.t, "ui_button_anonymous", false, true) }
      {
        clicked ?
          (<i className="pi pi-spin pi-spinner" style={{ padding: 0, marginLeft: '15px' }}/>) :
          (<Send style={{ transform: "rotate(45deg)" }}/>)
      }
    </Button>
  );
}

export default withTranslation()(ButtonAnonymous);
