import axios, { AxiosInstance } from 'axios';
import { DataStorage, KEY_ACCESS_TOKEN } from '../constants';

export class APIService {

  protected http: AxiosInstance;

  constructor(bearerAuth: boolean = true) {
    this.http = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: (bearerAuth)
        ? { 'Authorization': `Bearer ${DataStorage.getItem(KEY_ACCESS_TOKEN)}` }
        : {}
    });
  }
}