import { Input } from "@bbri/ui";
import { TFunction } from "i18next";
import { Messages } from "primereact/messages";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import Logo from "../shared/components/Logo";
import SelectLanguage from "../shared/components/SelectLanguage";
import ToolLogo from "../shared/components/ToolLogo";
import { SeverityEnum } from "../shared/enum/SeverityEnum";
import ButtonLogin from "./components/ButtonLogin";
import "./LoginPage.scss";
import { FormService } from "../form/services/FormService";

interface Props extends WithTranslation {
}

interface States {
  email: string;
  password: string;
}

class LoginPage extends React.Component<Props, States> {
  state: Readonly<States> = {
    email: "",
    password: "",
  };
  private messages: Messages | null;
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.messages = null;
    this.t = this.props.t;
    this.formService = new FormService();

  }

  handleLogin(isAuth: boolean) {
    if (!isAuth && this.messages) {
      this.messages.replace({
        severity: SeverityEnum.ERROR,
        closable: false,
        sticky: true,
        detail: this.formService.t(this.t, "error_invalid_credentials", false),
      });
    }
  }

  render() {
    return (
      <div className="container">
        <div className="p-grid login-wrapper p-align-center">
          <div className="p-col-12 p-md-6 p-md-offset-3">
            <div className="p-text-center login-logo-background">
              <Logo/>
            </div>
            <div className="login-card">
              <div className="login-logo-wrapper">
                <SelectLanguage/>
                <ToolLogo/>
              </div>
              <div className="login-content">
                <Messages ref={(el) => (this.messages = el)}/>
                <form className="p-grid p-formgrid p-fluid">
                  <div className="p-field p-col-12">
                    <Input.Text
                      id="email"
                      value={this.state.email}
                      placeholder={this.formService.t(this.t, 'login_email', false, false)}
                      onChange={(e) =>
                        this.setState({ email: (e.target as any).value })
                      }
                    />
                  </div>
                  <div className="p-field p-col-12">
                    <Input.Password
                      id="password"
                      value={this.state.password}
                      placeholder={this.formService.t(this.t, 'login_password', false, false)}
                      onChange={(e) =>
                        this.setState({ password: (e.target as any).value })
                      }
                    />
                  </div>
                </form>
                <ButtonLogin
                  email={this.state.email}
                  password={this.state.password}
                  onLogin={(e) => this.handleLogin(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(LoginPage);
