/*
 * Pour les icônes, il faut aller chercher sur le site https://icones8.fr/
 */

export const NODE_LIST: any = {
    nodeAnswerList: {
        name: 'node_answer_list',
        defaultLabel: 'Node answer_list',
        shapeBackgroundColor: "rgba(209, 209, 209,1)",
        iconBackgroundColor: "#0027e8",
        icon: "flow-chart",
        iconStyle: "material-outlined",
        iconColor: "ffffff"
    },
    nodeAnswerBool: {
        name: 'node_answer_bool',
        defaultLabel: 'Node answer_bool',
        shapeBackgroundColor: "rgba(209, 209, 209,1)",
        iconBackgroundColor: "#85bdbe",
        icon: "checked-2--v1",
        iconStyle: "material-outlined",
        iconColor: "000000"
    },
    nodeComputation: {
        name: 'node_computation',
        defaultLabel: 'Node computation',
        shapeBackgroundColor: "rgba(209, 209, 209,1)",
        iconBackgroundColor: "#ff9d00",
        icon: "calculator--v2",
        iconStyle: "material-outlined",
        iconColor: "000000"
    },
    nodeQuestionResult: {
        name: 'node_question_result',
        defaultLabel: 'Node question result',
        shapeBackgroundColor: "rgba(209, 209, 209,1)",
        iconBackgroundColor: "#fff200",
        icon: "external-question-call-to-action-bearicons-glyph-bearicons",
        iconStyle: "external-bearicons-glyph-bearicons",
        iconColor: "000000",
        className: 'square',
        end: true
    },
    nodeScript: {
        name: 'node_script',
        defaultLabel: 'Node script',
        shapeBackgroundColor: "rgba(209, 209, 209,1)",
        iconBackgroundColor: "#632704",
        icon: "source-code",
        iconStyle: "material-outlined",
        iconColor: "ffffff"
    },
    nodeRange: {
        name: 'node_range',
        defaultLabel: 'Node range',
        shapeBackgroundColor: "rgba(209, 209, 209,1)",
        iconBackgroundColor: "#44db42",
        icon: "more-or-equal",
        iconStyle: "ios-glyphs",
        iconColor: "000000"
    },
    nodeResult: {
        name: 'node_result',
        defaultLabel: 'Node result',
        shapeBackgroundColor: "rgba(209, 209, 209,1)",
        iconBackgroundColor: "#00f2ff",
        icon: "tags",
        iconStyle: "material-outlined",
        iconColor: "000000",
        className: 'square',
        end: true
    },
    nodeMin: {
        name: 'node_min',
        defaultLabel: 'Node min',
        shapeBackgroundColor: "rgba(209, 209, 209,1)",
        iconBackgroundColor: "#9300bf",
        icon: "zoom-out--v1",
        iconStyle: "material-outlined",
        iconColor: "ffffff"
    },
    nodeMax: {
        name: 'node_max',
        defaultLabel: 'Node max',
        shapeBackgroundColor: "rgba(209, 209, 209,1)",
        iconBackgroundColor: "#ff0000",
        icon: "zoom-in--v1",
        iconStyle: "material-outlined",
        iconColor: "000000"
    },
    nodeConcat: {
        name: 'node_concat',
        defaultLabel: 'Node concat',
        shapeBackgroundColor: "rgba(209, 209, 209,1)",
        iconBackgroundColor: "#5a68ad",
        icon: "dashboard-layout",
        iconStyle: "material-outlined",
        iconColor: "ffffff"
    },
    nodeMultipleAnswerList: {
        name: 'node_multiple_answer_list',
        defaultLabel: 'Node multiple_answer_list',
        shapeBackgroundColor: "rgba(209, 209, 209,1)",
        iconBackgroundColor: "#97bd5e",
        icon: "list",
        iconStyle: "material-outlined",
        iconColor: "ffffff",
    },
}

