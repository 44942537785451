import React, { Component } from "react";
import { TFunction } from 'i18next';
import { FormService } from '../../form/services/FormService';
import { withTranslation, WithTranslation } from 'react-i18next';

interface Props extends WithTranslation {

}

class ToolLogo extends Component<Props> {
  private t: TFunction;
  private formService: FormService;


  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.t = this.props.t;
  }

  render() {
    return <img className="tool-logo" src={this.formService.t(this.t, 'logo_app', false, false, false, true)} alt="Tool logo"/>;
  }
}

export default withTranslation()(ToolLogo);
