import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import AdminDecisionTrees from "./components/AdminDecisionTrees";
import AdminForm from "./components/AdminForm";
import AdminRequirements from "./components/AdminRequirements";
import AdminScripts from "./components/AdminScripts";
import AdminEditorJs from "./components/AdminEditorJs";
import AdminHtmls from "./components/AdminHTML";
import AdminEditorHtml from "./components/AdminEditorHtml";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import AdminSubmissions from './components/AdminSubmissions';
import AdminTranslations from "./components/AdminTranslations";
import AdminImages from "./components/AdminImages";
import AdminGraph from "./components/AdminGraph"
import AdminDiagram from "./components/AdminDiagram"
import AdminEditorDiagram from "./components/AdminEditorDiagram"
import AdminDiagramComment from "./components/AdminDiagramComment"


interface Props extends WithTranslation {
}

interface States {
}

class AdminPage extends React.Component<Props, States> {
  render() {
    const hasLocalTranslations = process.env.hasOwnProperty('REACT_APP_LOCAL_TRANSLATIONS') ?? process.env.REACT_APP_LOCAL_TRANSLATIONS === 'true' ?? false;

    return (
      <div className="container">
        <div className="p-grid">
          <div className="p-col-12 p-md-6">
            <Card title="Editor">
              <Button
                label="Access to editor"
                className="p-mb-2"
                onClick={ () => window.location.href = 'editor' }
              />
            </Card>
          </div>
          <div className="p-col-12 p-md-6">
            <AdminSubmissions/>
          </div>
          {
            hasLocalTranslations &&
              <React.Fragment>
                  <div className="p-col-12 p-md-6">
                      <AdminTranslations/>
                  </div>
                  <div className="p-col-12 p-md-6">
                      <AdminImages/>
                  </div>
              </React.Fragment>
          }
          <div className="p-col-12 p-md-6">
            <AdminForm/>
          </div>
          <div className="p-col-12 p-md-6">
            <AdminRequirements/>
          </div>
          <div className="p-col-12 p-md-6">
            <AdminDecisionTrees/>
          </div>
          <div className="p-col-12 p-md-6">
            <AdminScripts/>
          </div>
          <div className="p-col-12 p-md-6">
            <AdminEditorJs/>
          </div>
          <div className="p-col-12 p-md-6">
            <AdminEditorHtml/>
          </div>
          <div className="p-col-12 p-md-6">
            <AdminHtmls/>
          </div>
          <div className="p-col-12 p-md-6">
            <AdminGraph/>
          </div>
          <div className="p-col-12 p-md-6">
            <AdminDiagram/>
          </div>
          <div className="p-col-12 p-md-6">
            <AdminDiagramComment/>
          </div>
          <div className="p-col-12 p-md-12">
            <AdminEditorDiagram/>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AdminPage);
