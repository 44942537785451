import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import "./FormEditorTopbar.scss";
import { TFunction } from "i18next";
import { FormService } from "../../../form/services/FormService";
import { DecisionTreeEditorService } from "../../services/DecisionTreeEditorService";
import { Toast } from "primereact/toast";
import { SeverityEnum } from "../../../shared/enum/SeverityEnum";
import { Button } from "primereact/components/button/Button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Input } from "@bbri/ui";
import { FormEditorService } from "../services/FormEditorService";
import { InputText } from "primereact/inputtext";


interface States {
  currentQuestion: any;
  displayDialog: boolean;
  showConfirmDeleteDialog: boolean;
  showRenameDialog: boolean;
  showFormDialog: boolean;
  currentQuestionName: any;
}

interface Props extends WithTranslation {
  currentQuestion: any;
  refreshQuestionList: () => void;
  updateQuestionName: (currentQuestion: any, currentName: string) => any;
  currentForm: any;
  saveForm: () => any;
  deleteQuestion: (question) => void;

}

class FormEditorTopbar extends React.Component<Props, States> {

  private t: TFunction;
  private formService: FormService;
  private formEditorService: FormEditorService;
  private decisionTreeEditorService: DecisionTreeEditorService;
  private toast: Toast | null;
  private leftContents: any;
  private rightContents: any;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.formEditorService = new FormEditorService();
    this.decisionTreeEditorService = new DecisionTreeEditorService();
    this.t = this.props.t;
    this.toast = null;
    this.state = {
      currentQuestion: null,
      displayDialog: false,
      showConfirmDeleteDialog: false,
      showRenameDialog: false,
      showFormDialog: false,
      currentQuestionName: null
    };
  }

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    if (nextProps.currentQuestion) {
      this.setState({
        currentQuestion: nextProps.currentQuestion,
        currentQuestionName: nextProps.currentQuestion.question
      });
    }
  }

  private _renderRenameDialogFooter() {
    return (
      <div>
        <Button
          label="Annuler"
          icon="pi pi-times"
          onClick={() => this.setState({
            currentQuestionName: this.state.currentQuestion.question,
            showRenameDialog: false
          })}
          className="p-mb-2"
        />
        <Button
          label="Sauver"
          icon="pi pi-check"
          onClick={() => this._updateCurrentQuestionName()}
          className="p-mb-2"
        />
      </div>
    );
  }

  private _renderDeleteFooter() {
    return (
      <div>
        <Button
          label="Annuler"
          icon="pi pi-times"
          onClick={() => this.setState({ showConfirmDeleteDialog: false })}
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          className="p-button-danger"
        />
        <Button
          label="Supprimer"
          icon="pi pi-check"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
          onClick={() => this._deleteQuestion()}
          className="p-button-danger"
        />
      </div>
    );
  }

  private _updateCurrentQuestionName() {
    if (!this.state.currentQuestionName) {
      this.toast?.show({
        severity: SeverityEnum.ERROR,
        detail: "Le nom n'a pas été modifié !",
      });
      return;
    }
    if (this.props.currentQuestion) {
      this.props.updateQuestionName(this.state.currentQuestion, this.state.currentQuestionName).then(r => {
        if (r) {
          this.toast?.show({
            severity: SeverityEnum.SUCCESS,
            detail: 'Schéma sauvegardé',
          });
          let currentQuestion = this.state.currentQuestion;
          currentQuestion.question = this.state.currentQuestionName;
          this.setState({
            currentQuestion,
            showRenameDialog: false
          });
        }
      }).catch(() => {
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: 'Une erreur est survenue !',
        });
      });

    }
  }

  private _deleteQuestion() {
    if (this.props.currentQuestion) {
      this.props.deleteQuestion(this.state.currentQuestion);
      this.setState({
        showConfirmDeleteDialog: false
      });
    }
  }

  render() {

    this.leftContents = (
      <React.Fragment>
        <h2 className="editorTitle">Form editor</h2>
        <div className="toolbarCenterRequirement">
          <InputText
            value={this.state.currentQuestionName}
            onChange={(e: any) => {
              if (this.state.currentQuestion) {
                const currentQuestion = this.state.currentQuestion;
                currentQuestion.newName = e.currentTarget.value;
                this.setState({
                  currentQuestion,
                  currentQuestionName: e.currentTarget.value
                });
              }
            }}
            placeholder="Question name"
            style={{ width: '20rem' }}
            className="requirementInput"
            disabled={!this.state.currentQuestion}
          />
        </div>
      </React.Fragment>
    );
    this.rightContents = (
      <div className="action">
        <Button className="p-button-success"
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7', transform: "scaleX(-1)" }}
                disabled={!this.props.currentQuestion}
                icon="pi pi-eye"
                onClick={() => {
                  this.setState({
                    showFormDialog: true
                  });
                }}
        />
        <Button className="save"
                icon="pi pi-save"
                style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
                disabled={!this.props.currentQuestion}
                onClick={() => {
                  this.props.saveForm();
                }}/>
        <Button className="p-button-danger" icon="pi pi-trash" disabled={!this.props.currentQuestion} onClick={() => {
          if (this.props.currentQuestion) {
            this.setState({
              showConfirmDeleteDialog: true
            });
          }
          else {
            this.toast?.show({
              severity: SeverityEnum.ERROR,
              detail: 'Veuillez sélectionner une question',
            });

          }
        }}/>
      </div>
    );

    if (!this.state.currentQuestionName && this.state.currentQuestion) {
      this.setState({
        currentQuestionName: this.state.currentQuestion.question
      });
    }

    return (
      <div className="toolbar">
        <Toast ref={(el) => (this.toast = el)}/>
        <Toolbar left={this.leftContents} right={this.state.currentQuestion ? this.rightContents : null}/>
        <Dialog
          header={(this.props.currentQuestion) ? "Changer le nom de la question" : "Choisissez un nom pour cette question"}
          footer={this._renderRenameDialogFooter()}
          visible={this.state.showRenameDialog}
          style={{ width: '30vw' }}
          onHide={() => {
            this.setState({
              showRenameDialog: false
            });
          }}>
          <label htmlFor="name">
            Nom de la question
          </label>
          <Input.Text
            id="name"
            value={this.state.currentQuestionName ?? ''}
            onChange={(e) => {
              this.setState({
                currentQuestionName: e.target.value
              });
            }}
          />
        </Dialog>
        <Dialog
          visible={this.state.showConfirmDeleteDialog}
          onHide={() => this.setState({ showConfirmDeleteDialog: false })}
          header="Confirmation"
          footer={this._renderDeleteFooter()}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>Do you want to delete this question?</span>
          </div>
        </Dialog>
        <Dialog
          onHide={() => {

          }}
          header={this.props.currentQuestion?.key ?? ''}
          style={{ width: '1000px', height: '500px' }}
        >
        </Dialog>
        <Dialog
          onHide={() => {
            this.setState({
              showFormDialog: false
            });
          }}
          header="Form"
          visible={this.state.showFormDialog}
          footer={
            <div>
              <Button
                label="Copy"
                style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
                onClick={async () => {
                  prompt("Copy to clipboard: Ctrl+C, Enter", JSON.stringify(this.props.currentForm, null, 4) ?? '');
                }}
                className="p-button-danger"
              />
            </div>
          }
          style={{ width: '1000px' }}
        >
          <pre id="json">{JSON.stringify(this.props.currentForm, null, 4)}</pre>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(FormEditorTopbar);
