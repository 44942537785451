import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { Button } from 'primereact/button';
import { Card } from "primereact/card";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FileUtils } from "../../shared/utils/FileUtils";
import { AdminTranslationService } from "../services/AdminTranslationService";
import { RichText } from "prismic-reactjs";
import { Image } from "../models/Image";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FormService } from "../../form/services/FormService";
import { Dialog } from "primereact/dialog";

interface Props extends WithTranslation {
}

interface States {
  translations: any[];
  displayConfirmation: boolean;
  toDelete: string | null;
}

class AdminTranslations extends React.Component<Props, States> {
  private readonly translationService: AdminTranslationService;
  private toast: Toast | null;
  private fileUpload: FileUpload | null;
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.translationService = new AdminTranslationService();
    this.formService = new FormService();
    this.t = this.props.t;
    this.toast = null;
    this.fileUpload = null;

    this.state = {
      translations: [],
      displayConfirmation: false,
      toDelete: null,
    };
  }


  componentDidMount() {
    this.getTranslations();
  }

  /**
   * Refresh decision trees
   */
  getTranslations() {
    this.translationService
      .getMany()
      .then((response: AxiosResponse<Image[]>) => {
        this.setState({ translations: response.data });
      });
  }

  /**
   * On upload
   */
  handleUpload(e: { files: File[] }) {
    if (!e.files) {
      return;
    }
    this.translationService
      .uploadTranslations(e.files)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.t("ui_admin_translations_toast_success"),
        });
        this.fileUpload?.clear();
        this.getTranslations();
      })
      .catch(() => {
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: this.t("ui_admin_translations_toast_error"),
        });
      });
  }

  /**
   * On download
   */
  handleDownload(code: string) {
    this.translationService
      .downloadOne(code)
      .then((response: AxiosResponse<Blob>) => {
        FileUtils.downloadFile(response);
      });
  }

  handleDelete() {
    console.log(this.state);
    if (!this.state.toDelete) {
      return;
    }

    this.translationService.deleteOne(this.state.toDelete).then(() => {
      this.toast?.show({
        severity: SeverityEnum.SUCCESS,
        detail: this.t("ui_admin_translation_delete_toast_success"),
      });
      this.setState({ displayConfirmation: false, toDelete: null });
      this.getTranslations();
    });
  }

  renderDialogFooter() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={ () => this.setState({ displayConfirmation: false }) }
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={ () => this.handleDelete() }
          autoFocus
        />
      </div>
    );
  }

  actionBodyTemplate(rowData: any) {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-download"
          className="p-button-secondary p-mr-1"
          onClick={ () => this.handleDownload(rowData.code) }
        ></Button>
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={ () =>
            this.setState({ displayConfirmation: true, toDelete: rowData.code })
          }
        ></Button>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Card title={ RichText.asText(this.t("ui_admin_translations_title", { returnObjects: true })) }>
        <Toast ref={ (el) => (this.toast = el) }/>
        <div className="p-grid">
          <div className="p-col-12">
            <FileUpload
              ref={ (el) => (this.fileUpload = el) }
              accept="application/json"
              customUpload={ true }
              multiple={ true }
              uploadHandler={ (e: { files: File[] }) => this.handleUpload(e) }
              // emptyTemplate={() => <p className="p-m-0">{this.t("ui_admin_translations_upload")}</p>}
            ></FileUpload>
          </div>

          <div className="p-col-12">
            <DataTable
              value={ this.state.translations }
              rows={ 5 }
              paginator={ true }
              rowsPerPageOptions={ [5, 10, 20] }
            >
              <Column
                field="code"
                filter
                filterPlaceholder={ RichText.asText(this.t(
                  "ui_admin_translation_search_by_code",
                  { returnObjects: true }
                )) }
                header={
                  this.formService.checkIfValue(RichText.render(this.t("ui_admin_translation_code", { returnObjects: true })), "ui_admin_translation_code")
                }
              />
              <Column
                body={ (rowData: Image) =>
                  this.actionBodyTemplate(rowData)
                }
                headerStyle={ { width: "8rem" } }
                bodyStyle={ { textAlign: "center" } }
              ></Column>
            </DataTable>
            <Dialog
              header="Confirmation"
              visible={ this.state.displayConfirmation }
              modal
              style={ { width: "350px" } }
              footer={ this.renderDialogFooter() }
              onHide={ () => this.setState({ displayConfirmation: false }) }
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle p-mr-3"
                  style={ { fontSize: "2rem" } }
                />
                <span>Are you sure you want to proceed?</span>
              </div>
            </Dialog>
          </div>
        </div>
      </Card>
    );
  }
}

export default withTranslation()(AdminTranslations);
