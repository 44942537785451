import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FileUtils } from "../../shared/utils/FileUtils";
import { Html } from "../models/HTML";
import { RichText } from "prismic-reactjs";
import { FormService } from "../../form/services/FormService";
import { AdminHtmlsService } from "../services/AdminHTMLService";

interface Props extends WithTranslation {
}

interface States {
  htmls: Html[];
  displayConfirmation: boolean;
  toDelete: string | null;
}

class AdminHtmls extends React.Component<Props, States> {
  private readonly htmlService: AdminHtmlsService;
  private toast: Toast | null;
  private fileUpload: FileUpload | null;
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.htmlService = new AdminHtmlsService();
    this.t = this.props.t;
    this.toast = null;
    this.fileUpload = null;
    this.formService = new FormService();
    this.state = {
      htmls: [],
      displayConfirmation: false,
      toDelete: null,
    };
  }

  componentDidMount() {
    this.getHtmls();
  }

  /**
   * Refresh htmls
   */
  getHtmls() {
    this.htmlService
      .getMany()
      .then((response: AxiosResponse<Html[]>) => {
        this.setState({ htmls: response.data });
      });
  }

  /**
   * On upload
   */
  handleUpload(e: { files: File[] }) {
    if (!e.files) {
      return;
    }
    this.htmlService
      .uploadMany(e.files)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.formService.t(this.t, "ui_admin_html_upload_toast_success", false),
        });
        this.fileUpload?.clear();
        this.getHtmls();
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `${this.formService.t(this.t, "ui_admin_html_upload_toast_error", false)} : ${message}`,
        });
      });
  }

  /**
   * On download
   */
  handleDownload(name: string) {
    this.htmlService
      .downloadOne(name)
      .then((response: AxiosResponse<Blob>) => {
        FileUtils.downloadFile(response);
      });
  }

  /**
   * Delete html
   */
  handleDelete() {
    if (!this.state.toDelete) {
      return;
    }

    this.htmlService.deleteOne(this.state.toDelete).then(() => {
      this.toast?.show({
        severity: SeverityEnum.SUCCESS,
        detail: this.t("ui_admin_html_delete_toast_success"),
      });
      this.setState({ displayConfirmation: false, toDelete: null });
      this.getHtmls();
    });
  }

  renderDialogFooter() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ displayConfirmation: false })}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.handleDelete()}
          autoFocus
        />
      </div>
    );
  }

  actionBodyTemplate(rowData: Html) {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-download"
          className="p-button-secondary p-mr-1"
          onClick={() => this.handleDownload(rowData.fileName)}
        ></Button>
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() =>
            this.setState({ displayConfirmation: true, toDelete: rowData.fileName })
          }
        ></Button>
      </React.Fragment>
    );
  }

  render() {
    return (
      <Card title={this.formService.t(this.t, "ui_admin_htmls_title", false)}>
        <Toast ref={(el) => (this.toast = el)}/>
        <div className="p-grid">
          <div className="p-col-12">
            <FileUpload
              ref={(el) => (this.fileUpload = el)}
              accept=".html"
              customUpload={true}
              multiple={true}
              uploadHandler={(e: { files: File[] }) => this.handleUpload(e)}
              // emptyTemplate={() => <p className="p-m-0">{this.t("ui_admin_html_upload_content")}</p>}
            ></FileUpload>
          </div>
          <div className="p-col-12">
            <DataTable
              value={this.state.htmls}
              rows={5}
              paginator={true}
              rowsPerPageOptions={[5, 10, 20]}
            >
              <Column
                field="fileName"
                filter
                filterPlaceholder={RichText.asText(this.t(
                  "ui_admin_html_search_by_name",
                  { returnObjects: true }
                ))}
                header={this.formService.checkIfValue(RichText.render(this.t("ui_admin_html_name", { returnObjects: true })), "ui_admin_html_name")}
              />
              <Column
                body={(rowData: Html) =>
                  this.actionBodyTemplate(rowData)
                }
                headerStyle={{ width: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
            <Dialog
              header="Confirmation"
              visible={this.state.displayConfirmation}
              modal
              style={{ width: "350px" }}
              footer={this.renderDialogFooter()}
              onHide={() => this.setState({ displayConfirmation: false })}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle p-mr-3"
                  style={{ fontSize: "2rem" }}
                />
                <span>Are you sure you want to proceed?</span>
              </div>
            </Dialog>
          </div>
        </div>
      </Card>
    );
  }
}

export default withTranslation()(AdminHtmls);
