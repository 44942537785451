import { APIService } from '../../shared/services/APIService';
import { AxiosResponse } from "axios";

export class AdminTranslationService extends APIService {

  /**
   * Upload and replace the translations
   *
   * @role Admin
   */
  uploadTranslations(files: File[]) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    return this.http.put<void>('/translations/upload', formData);
  }

  /**
   * Get many decision trees
   */
  getMany(): Promise<AxiosResponse<any[]>> {
    return this.http.get<any[]>('/translations');
  }

  /**
   * Download a decision tree by name
   */
  downloadOne(code: string) {
    return this.http.get<Blob>(`/translations/${code}/download`, { responseType: 'blob' });
  }

  deleteOne(code: string) {
    return this.http.delete<void>(`/translations/${code}`);
  }
}
