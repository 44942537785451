import i18n from "i18next";
import BrowserDetector from "i18next-browser-languagedetector";
import HttpApi, { BackendOptions } from "i18next-http-backend";
import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";
import { initReactI18next, WithTranslation, withTranslation, } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminPage from "./admin/AdminPage";
import FormPage from "./form/FormPage";
import MainLayout from "./layout/MainLayout";
import AdminRoute from "./login/components/AdminRoute";
import PrivateRoute from "./login/components/PrivateRoute";
import LoginPage from "./login/LoginPage";
import { FormService } from "./form/services/FormService";
import AnonymousPage from "./login/AnonymousPage";
import ReactGA from 'react-ga';
import { AdvertisingInfoResponse, Idfa } from "@sparkfabrik/capacitor-plugin-idfa";
import DecisionTreeEditorPage from "./editor/DecisionTreeEditorPage";
import { Capacitor } from "@capacitor/core";
import UserPage from "./user/UserPage"

i18n
  .use(initReactI18next)
  .use(BrowserDetector)
  .use(HttpApi)
  .init({
    lng: "fr",
    fallbackLng: "fr",
    backend: {
      loadPath: `${process.env.REACT_APP_API_URL}/translations/{{lng}}`,
    } as BackendOptions,
  });

interface Props extends WithTranslation {
}

class App extends Component<Props> {

  private formService: FormService;
  private languagesMapping: any;
  private isMobile = Capacitor.isNativePlatform();

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();


    if (!this.isMobile) {
      const url = new URL(window.location.href);
      this.languagesMapping = {
        'fr': process.env?.REACT_APP_URL_FR ? process.env?.REACT_APP_URL_FR : url.hostname,
        'nl': process.env?.REACT_APP_URL_NL ? process.env?.REACT_APP_URL_NL : url.hostname,
      };
    }
  }

  componentDidMount() {
    this.updateTitle();
    if (!this.isMobile) {
      this.initLanguage(this.props.i18n.language);
    }
    this.props.i18n.on("languageChanged", this.updateTitle.bind(this));
    if (!this.isMobile) {
      this.props.i18n.on("languageChanged", this.updateLanguage.bind(this));
    }
  }

  componentWillUnmount() {
    this.props.i18n.off("languageChanged", this.updateTitle.bind(this));
    if (!this.isMobile) {
      this.props.i18n.off("languageChanged", this.updateLanguage.bind(this));
    }
  }

  initLanguage(language: string) {
    const url = new URL(window.location.href);

    if (url.searchParams.has('locale')) {
      const locale = url.searchParams.get('locale')!;
      if (this.languagesMapping.hasOwnProperty(locale) && this.languagesMapping[locale] === url.hostname) {
        this.props.i18n.changeLanguage(locale);
        return;
      }
    }

    const targetLanguage = Object.keys(this.languagesMapping).find(f => this.languagesMapping[f] === url.hostname);
    if (!targetLanguage) {
      url.hostname = this.languagesMapping[language];
      window.location.href = url.toString();
      return;
    }

    if (language !== targetLanguage) {
      this.props.i18n.changeLanguage(targetLanguage!.toString());
    }
  }

  updateLanguage(language: string) {
    const url = new URL(window.location.href);
    let targetLanguage = Object.keys(this.languagesMapping).find(f => this.languagesMapping[f] === url.hostname);

    if (url.searchParams.has('locale')) {
      const locale = url.searchParams.get('locale')!;
      if (this.languagesMapping.hasOwnProperty(locale) && this.languagesMapping[locale] === url.hostname) {
        targetLanguage = locale;
      }
    }

    if (!targetLanguage) {
      return;
    }

    if (language === targetLanguage) {
      return;
    }

    url.hostname = this.languagesMapping[language];
    url.searchParams.set('locale', language);
    window.location.href = url.toString();
  }

  updateTitle() {
    document.title = this.formService.t(this.props.t, 'title_app', false, true);
    const favicon = document.getElementById("favicon");
    if (favicon) {
      (favicon as HTMLLinkElement).href = this.formService.t(this.props.t, 'favicon', false, false, false, true);
    }
  }

  render() {
    if (process.env["REACT_APP_GA_ID"]) {
      Idfa.getAdvertisingInfo().then((response: AdvertisingInfoResponse) => {
        if (Capacitor.getPlatform() !== 'web' && response.isAdTrackingLimited) {
          console.log('ReactGA will not be initialized');
        }
        else {
          console.log('ReactGA.initialize');
          ReactGA.initialize(process.env["REACT_APP_GA_ID"] as string);
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }

    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route path="/home">
              <AnonymousPage/>
            </Route>
            <Route path="/login">
              <LoginPage/>
            </Route>
            {/* <PrivateRoute path="/user">
              <MainLayout>
                <UserPage/>
              </MainLayout>
            </PrivateRoute> */}
            <AdminRoute path="/admin">
              <AdminPage/>
            </AdminRoute>
            <AdminRoute path="/editor">
              <DecisionTreeEditorPage/>
            </AdminRoute>
            <PrivateRoute path="/">
              <MainLayout>
                <FormPage/>
              </MainLayout>
            </PrivateRoute>
          </Switch>
        </Router>
        {Capacitor.isNativePlatform() ? '' :
          <CookieConsent
            buttonText={this.formService.t(this.props.t, 'cookie_consent_button_confirm', true)}
            contentClasses="cookie-consent-content"
            buttonClasses="cookie-consent-button"
            disableStyles={true}>
            {this.formService.t(this.props.t, 'cookie_consent_text', true)}
          </CookieConsent>}
      </React.Fragment>
    );
  }
}

export default withTranslation()(App);
