import { TFunction } from "i18next";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./FormInputEmail.scss";
import { FormService } from "../services/FormService";
import { FormQuestion } from "../models/FormQuestion";

interface Props extends WithTranslation {
  question: FormQuestion;
  onChange: (v: any) => void;
  value?: any;
  disabled?: boolean;
}


interface States {
  value: string;
	hasError: boolean;
	isBlur: boolean;
}

class FormInputEmail extends Component<Props, States> {
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);

    this.t = this.props.t;
    this.formService = new FormService();
		this.state = {
			hasError: false,
			value: '',
			isBlur: false
		};
		this.handleBlur = this.handleBlur.bind(this);
		this.handleChange = this.handleChange.bind(this);
  }

	private _validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

	handleBlur(e: React.FocusEvent<HTMLInputElement>) {
		if (e.target.value !==  "") {
			this.setState({...this.state, isBlur: true});
		}
	}

	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.value ===  "") {
			this.setState({...this.state, value: e.target.value, hasError: false, isBlur: false});
		}else {
			this.setState({...this.state, value: e.target.value, hasError: !this._validateEmail(e.target.value)});
		}
		this.props.onChange(e);
	}

  render() {
    return (
      <React.Fragment>
          <div>
            <input
							className={`input-form-email ${(this.state.hasError && this.state.isBlur) ? 'error' : ''}`}
              type="email"
							value={this.props.value}
              id={ this.props.question.question }
              disabled={ this.props.disabled }
              onChange={this.handleChange}
							onBlur={ this.handleBlur }
							autoComplete='off'
            />
						{(this.state.hasError && this.state.isBlur) && <p className="input-form-error">Email Invalide</p> }
          </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(FormInputEmail);
