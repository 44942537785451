import {APIService} from "../../../shared/services/APIService";
import {Form} from "../../../form/models/Form";
import {Schema} from "../../models/Schema";
import {FormQuestion} from "../../../form/models/FormQuestion";

export class FormEditorService extends APIService {

    /**
     * Get form
     */
    getForm() {
        return this.http.get<Form>('/forms/default');
    }

    updateQuestionName(schema: Schema | null, currentTreeName: string | null) {
        return this.http.put<void>(`/editor-decision-trees/update-name/${currentTreeName}`, schema);
    }

    updateQuestions(questions: FormQuestion[]) {
        return this.http.put<void>(`/forms/update-questions`, {questions});
    }

    updateForm(form: Form | null) {
        return this.http.put<void>(`/forms/update-form`, {form});
    }
}
