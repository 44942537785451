import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { FileUtils } from "../../shared/utils/FileUtils";
import { AdminSubmissionsService } from "../services/AdminSubmissionsService";
import { RichText } from "prismic-reactjs";

interface Props extends WithTranslation {
}

interface States {
}

class AdminSubmissions extends Component<Props, States> {
  private readonly submissionService: AdminSubmissionsService;
  private t: TFunction;

  constructor(props: Props) {
    super(props);
    this.submissionService = new AdminSubmissionsService();

    this.t = this.props.t;
  }

  /**
   * On download
   */
  handleDownload() {
    this.submissionService.downloadSubmissions().then((response: AxiosResponse<Blob>) => {
      FileUtils.downloadFile(response);
    });
  }

  render() {
    return (
      <Card title={RichText.asText(this.t("ui_admin_submissions_title", { returnObjects: true }))}>
        <Button
          label={RichText.asText(this.t("ui_admin_submissions_download_button", { returnObjects: true }))}
          className="p-mb-2"
          onClick={() => this.handleDownload()}
        />
      </Card>
    );
  }
}

export default withTranslation()(AdminSubmissions);
