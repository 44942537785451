import { AxiosResponse } from "axios";
import { TFunction } from "i18next";
import { Input, Select } from "@bbri/ui";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { FileUtils } from "../../shared/utils/FileUtils";
import { Html } from "../models/HTML";
import { RichText } from "prismic-reactjs";
import { FormService } from "../../form/services/FormService";
import { AdminHtmlsService } from "../services/AdminHTMLService";

import Editor from "@monaco-editor/react";
import { HtmlDTO } from "../dto/HtmlDTO";


const options = [
  {
    id: 0,
    name: "Create or update html file",
  },
  {
    id: 1,
    name: "Upload existing html file",
  }
];

interface Props extends WithTranslation {
}

interface States {
  optionChoice: any;
  htmls: Html[];
  displayConfirmation: boolean;
  displayNewHtmlConfirmation: boolean;
  toDelete: string | null;
  editorText: string;
  editorScriptName: string;
}

class AdminEditorHtml extends React.Component<Props, States> {
  private readonly htmlService: AdminHtmlsService;
  private toast: Toast | null;
  private fileUpload: FileUpload | null;
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.htmlService = new AdminHtmlsService();
    this.t = this.props.t;
    this.toast = null;
    this.fileUpload = null;
    this.formService = new FormService();
    this.state = {
      optionChoice: options[0],
      htmls: [],
      displayConfirmation: false,
      displayNewHtmlConfirmation: false,
      toDelete: null,
      editorText: "",
      editorScriptName: ""
    };

    this.showChoice = this.showChoice.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.resetEditorText = this.resetEditorText.bind(this);
    this.handleOneUpload = this.handleOneUpload.bind(this);
  }

  componentDidMount() {
    this.getHtmls();
  }


  /**
   * Show the choosen option
   */
  showChoice(value, event) {
    this.setState({ optionChoice: value });
  }


  /**
   * Refresh htmls
   */
  getHtmls() {
    this.htmlService
      .getMany()
      .then((response: AxiosResponse<Html[]>) => {
        this.setState({ htmls: response.data });
      });
  }

  getHtmlToViewText(str: string) {
    if (!str || !str.includes(".html")) {
      return;
    }

    this.htmlService
      .getOne(str)
      .then((response: AxiosResponse<string>) => {

        this.setState({ editorText: response.data, editorScriptName: str });

      });
  }

  /**
   * On upload
   */
  handleUpload(e: { files: File[] }) {
    if (!e.files) {
      return;
    }
    this.htmlService
      .uploadMany(e.files)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.formService.t(this.t, "ui_admin_html_upload_toast_success", false),
        });
        this.fileUpload?.clear();
        this.getHtmls();
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `${this.formService.t(this.t, "ui_admin_html_upload_toast_error", false)} : ${message}`,
        });
      });
  }

  /**
   * On upload for one html
   */
  handleOneUpload() {

    let fileName = this.state.editorScriptName;

    if (!fileName) {
      return;
    }

    let extensionPart = fileName.substring(fileName.lastIndexOf('.'));
    const allowedTypes = ['.html'];

    // = Validate the types
    if (!allowedTypes.includes(extensionPart)) {
      fileName += ".html";
    }


    const dto = new HtmlDTO(fileName, this.state.editorText);

    this.htmlService
      .uploadOne(dto)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: this.formService.t(this.t, "ui_admin_html_upload_one_toast_success", false),
        });
        this.resetEditorText();
        this.getHtmls();
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `${this.formService.t(this.t, "ui_admin_html_upload_one_toast_error", false)} : ${message}`,
        });
      });
  }


  /**
   * On download
   */
  handleDownload(name: string) {
    this.htmlService
      .downloadOne(name)
      .then((response: AxiosResponse<Blob>) => {
        FileUtils.downloadFile(response);
      });
  }

  /**
   * Delete html
   */
  handleDelete() {
    if (!this.state.toDelete) {
      return;
    }

    this.htmlService.deleteOne(this.state.toDelete).then(() => {
      this.toast?.show({
        severity: SeverityEnum.SUCCESS,
        detail: this.t("ui_admin_html_delete_toast_success"),
      });
      this.setState({ displayConfirmation: false, toDelete: null });
      this.getHtmls();
    });
  }

  renderDialogFooter() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ displayConfirmation: false })}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.handleDelete()}
          autoFocus
        />
      </div>
    );
  }

  actionBodyTemplate(rowData: Html) {
    return (
      <React.Fragment>
        <Button
          type="button"
          icon="pi pi-download"
          className="p-button-secondary p-mr-1"
          onClick={() => this.handleDownload(rowData.fileName)}
        ></Button>
        <Button
          type="button"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() =>
            this.setState({ displayConfirmation: true, toDelete: rowData.fileName })
          }
        ></Button>
      </React.Fragment>
    );
  }


  handleEditorChange(value, event) {
    this.setState({ editorText: value });
  }

  onRowClick(event) {
    this.getHtmlToViewText(event.originalEvent.target.innerHTML);
  }


  resetEditorText() {
    this.setState({
      editorText: "<!-- create a new html -->",
      displayNewHtmlConfirmation: false,
      editorScriptName: ""
    });
  }

  validationWindowForNewScript() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ displayNewHtmlConfirmation: false })}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => this.resetEditorText()}
          autoFocus
        />
      </div>
    );
  }


  render() {
    return (
      <Card title={this.formService.t(this.t, "ui_admin_editor_html_title", false)}>
        <Toast ref={(el) => (this.toast = el)}/>
        <div className="p-grid">

          <div className="p-col-12 p-d-flex p-jc-center">
            <Select
              label={"Options:"}
              options={options}
              placeholder={"Select one of the available options"}
              selection={this.state.optionChoice}
              displayProperty="name"
              onChange={this.showChoice}
            />
          </div>


          {/*
                            Create new html option 
                    */}

          {this.state.optionChoice.id === 0 &&
              <>
                  <div className="p-col-12 p-d-flex p-jc-center">

                            <span className="p-float-label">
                                <Input.Text
                                    value={this.state.editorScriptName}
                                    onChange={(e) => this.setState({ editorScriptName: e.currentTarget.value })}

                                    placeholder="Html file name"
                                    required={true}
                                    style={{ width: '20rem' }}/>
                            </span>

                  </div>
                  <div className="p-col-12">
                      <Editor
                          height="20rem"
                          defaultLanguage="html"
                          defaultValue="<!-- write down html code -->"
                          value={this.state.editorText}
                          onChange={this.handleEditorChange}/>
                  </div>
                  <div className="p-col-12 p-d-flex p-jc-center">
                      <Button
                          label="New html"
                          icon="pi pi-file"
                          className="p-button p-button-info  p-m-2"
                          onClick={() => this.setState({ displayNewHtmlConfirmation: true })}/>
                      <Button
                          label="Submit"
                          icon="pi pi-check"
                          className="p-button p-button-info  p-m-2 "
                          onClick={this.handleOneUpload}/>
                  </div>
                  <div className="p-col-12">
                      <Dialog
                          header="Confirmation"
                          visible={this.state.displayNewHtmlConfirmation}
                          modal
                          style={{ width: "350px" }}
                          footer={this.validationWindowForNewScript()}
                          onHide={() => this.setState({ displayNewHtmlConfirmation: false })}
                      >
                          <div className="confirmation-content">
                              <i
                                  className="pi pi-exclamation-triangle p-mr-3"
                                  style={{ fontSize: "2rem" }}/>
                              <span>Are you sure you want to proceed?</span>
                          </div>
                      </Dialog>
                  </div>
              </>
          }

          {/*
                            Upload existing html option 
                    */}

          {this.state.optionChoice.id === 1 &&
              <>
                  <div className="p-col-12">
                      <FileUpload
                          ref={(el) => (this.fileUpload = el)}
                          accept=".html"
                          customUpload={true}
                          multiple={true}
                          uploadHandler={(e: { files: File[] }) => this.handleUpload(e)}
                        // emptyTemplate={() => <p className="p-m-0">{this.t("ui_admin_html_upload_content")}</p>}
                      ></FileUpload>
                  </div>
              </>
          }


          {/*
                            Used for both options 
                    */}
          <div className="p-col-12">
            <DataTable
              value={this.state.htmls}
              rows={5}
              paginator={true}
              rowsPerPageOptions={[5, 10, 20]}
              onRowClick={this.onRowClick.bind(this)}
            >
              <Column
                field="fileName"
                filter
                filterPlaceholder={RichText.asText(this.t(
                  "ui_admin_html_search_by_name",
                  { returnObjects: true }
                ))}
                header={this.formService.checkIfValue(RichText.render(this.t("ui_admin_html_name", { returnObjects: true })), "ui_admin_html_name")}
              />
              <Column
                body={(rowData: Html) =>
                  this.actionBodyTemplate(rowData)
                }
                headerStyle={{ width: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
              ></Column>
            </DataTable>
            <Dialog
              header="Confirmation"
              visible={this.state.displayConfirmation}
              modal
              style={{ width: "350px" }}
              footer={this.renderDialogFooter()}
              onHide={() => this.setState({ displayConfirmation: false })}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle p-mr-3"
                  style={{ fontSize: "2rem" }}
                />
                <span>Are you sure you want to proceed?</span>
              </div>
            </Dialog>
          </div>
        </div>
      </Card>
    );
  }
}

export default withTranslation()(AdminEditorHtml);
