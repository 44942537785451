import { APIService } from '../../shared/services/APIService';

export class AdminSubmissionsService extends APIService {
  /**
   * Download the current submissions
   *
   * @role Admin
   */
  downloadSubmissions() {
    return this.http.get<Blob>('/forms/default/submissions/download', { responseType: 'blob' });
  }
}
