import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from "@bbri/ui";
import { TFunction } from 'i18next';
import "./About.scss";
import { FormService } from "../../form/services/FormService";
import ReactGA from "react-ga";
import { ReactGAUtils } from "../utils/ReactGAUtils";

interface Props extends WithTranslation {
}

class About extends Component<Props> {
  private t: TFunction;
  private formService: FormService;

  constructor(props: Props) {
    super(props);
    this.t = this.props.t;
    this.formService = new FormService();
  }

  openAbout() {
    window.location.href = this.formService.t(this.t, 'url_about', false);
  }

  render() {
    return (
      <div className="about">
        <Button
          id="about"
          onClick={() => {
            ReactGA.event({
              category: ReactGAUtils.getCategory(),
              action: 'click',
              label: 'À propos'
            });
            this.openAbout();
          }}
        >
          <React.Fragment>
              <span className="full">
                {this.formService.t(this.t, "button_about", false, true)}
              </span>
            <span className="compact">
                ?
              </span>
          </React.Fragment>
        </Button>
      </div>
    );
  }
}

export default withTranslation()(About);
