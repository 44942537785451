export enum OperatorType {
  NOT_EQUAL = 'neq',
  EQUAL = 'eq',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL_TO = 'gte',
  LOWER_THAN = 'lt',
  LOWER_THAN_OR_EQUAL_TO = 'lte',
  IN = 'in',
  NOT_IN = 'nin',
  AND = 'and',
  OR = 'or',
  STRICTLY_EQUAL = 'strictly_eq',
  STRICTLY_INCLUDED = 'strictly_included',
  STRICTLY_INCLUDES = 'strictly_includes'
}
