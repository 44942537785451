export class ReactGAUtils {
  static category: string;
  static getCategory(): string {
    if (ReactGAUtils.category) {
      return ReactGAUtils.category;
    }

    const url = new URL(window.location.href);

    let category: string | null = null;

    if (url.hostname.endsWith('buildwise.be')) {
      category = url.hostname.replace(/^(.+)[.]buildwise[.]be$/, '$1');
    }
    else if (url.hostname.endsWith('azurewebsites.net')) {
      category = url.hostname.replace(/^(.+)[.]azurewebsites[.]net$/, '$1');
    }

    if (category) {
      if (category.startsWith('bbri-betonapp')) {
        category = category.replace(/^bbri-betonapp(.+)$/, '$1');
      }
    }

    if (!category) {
      category = url.hostname;
    }

    ReactGAUtils.category = category;

    console.log(`[ReactGAUtils] ${url.hostname} -> ${ReactGAUtils.category}`)

    return ReactGAUtils.category;
  }
}
