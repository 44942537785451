import React, { Component } from 'react';
import "./import-jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import "./style.scss";
import draw2d from "draw2d";
import { Toast } from "primereact/toast";
import { Reader } from "./reader";
import { NodeAnswerList } from "./shapes/node-answer-list";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router";
import { TFunction } from "i18next";
import { ConnectionCreatePolicy } from "./policies/connection-create-policy";
import Topbar from "./components/topbar";
import { DecisionTreeEditorService } from "../services/DecisionTreeEditorService";
import { Schema } from "../models/Schema";
import Sidebar from "./components/sidebar";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/components/button/Button";
import { SeverityEnum } from "../../shared/enum/SeverityEnum";
import { InputText } from "primereact/inputtext";
import { NodeAnswerBool } from "./shapes/node-answer-bool";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { FormService } from "../../form/services/FormService";
import { DecisionTreeService } from "../../form/services/DecisionTreeService";
import { NodeRange } from "./shapes/node-range";
import { NodeComputation } from "./shapes/node-computation";
import { NodeQuestionResult } from "./shapes/node-question-result";
import { MultiSelect } from "primereact/multiselect";
import { NodeResult } from "./shapes/node-result";
import { Checkbox } from "primereact/checkbox";
import { QuestionType } from "../../form/enum/QuestionType";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { OperatorType } from "../../form/enum/OperatorType";
import { NodeMin } from "./shapes/node-min";
import { NodeMax } from "./shapes/node-max";
import { TransitionLHS } from "./shapes/transition-lhs";
import { TransitionRHS } from "./shapes/transition-rhs";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Column } from "primereact/components/column/Column";
import { InputNumber } from "primereact/inputnumber";
import { NodeScript } from "./shapes/node-script";
import { Script } from "../models/Script";
import { AdminScriptsService } from "../services/AdminScriptsService";
import { NodeConcat } from "./shapes/node-concat";
import { Transition } from "./shapes/transition";
import { NodeMultipleAnswerList } from "./shapes/node-multiple-answer-list";
import { NODE_LIST } from "./enums/shape";
import { TransitionConcat } from "./shapes/transition-concat";

const draw2d2 = draw2d as any;


declare var window: any;

window.draw2d = draw2d;
window.allNodes = [];

interface States {
  selectedShape: any;
  nodeType: string;
  selection: any;
  currentName: any;
  canvas: any;
  schema: Schema | null;
  showEditPanel: boolean;
  schemas: Schema[];
  showNewTreeDialog: boolean;
  refreshSchemasList: boolean;
  currentKey: any;
  changes: boolean;
  currentZoom: number;
  questionList: { type: string, name: string, label: string, values: string[] }[];
  questionListAnswerList: { type: string, name: string, label: string, values: string[] }[];
  questionListAnswerBool: { name: string, label: string, values: string[] }[];
  questionListResult: { name: string, label: string, values: string[] }[];
  decisionTreeList: string[];
  refreshScriptsState: boolean;
  scriptList: Script[];
  currentToolsName: string;
}

interface Props extends WithTranslation, RouteComponentProps {
}

class Canvas extends Component<Props, States> {


  static ZOOM_PERCENT = 10;
  zoomLevel: number;
  private toast: Toast | null;
  private canvas: any;
  private selection: any;
  private t: TFunction;
  private decisionTreeEditorService: DecisionTreeEditorService;
  private formService: FormService;
  private decisionTreeService: DecisionTreeService;
  private scriptService: AdminScriptsService;

  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.toast = null;
    this.zoomLevel = 100;
    this.decisionTreeEditorService = new DecisionTreeEditorService();
    this.formService = new FormService();
    this.decisionTreeService = new DecisionTreeService();
    this.scriptService = new AdminScriptsService();
    this.state = {
      selectedShape: null,
      nodeType: '',
      selection: null,
      currentName: null,
      canvas: null,
      schema: null,
      showEditPanel: false,
      schemas: [],
      showNewTreeDialog: false,
      refreshSchemasList: false,
      currentKey: null,
      changes: false,
      currentZoom: 1.5,
      decisionTreeList: [],
      questionList: [],
      questionListAnswerList: [],
      questionListAnswerBool: [],
      questionListResult: [],
      refreshScriptsState: false,
      scriptList: [],
      currentToolsName: ''
    };
  }


  componentDidMount() {
    this._getDatas();

    this._draw();

  }

  private _draw() {
    // setup canvas and toolbar
    this.canvas = new draw2d.Canvas("canvas");

    this.setState({
      canvas: this.canvas
    });

    this._setupCanvas();
    this._setupOnDropAction();

    this.decisionTreeEditorService.findAll().then((res) => {
      if (res) {
        this.setState({
          schemas: res.data
        });
      }
    });

  };

  private _loadSchema(schema: Schema) {
    this.setState({
      schema
    });

    this.state.canvas.clear();

    this.decisionTreeEditorService.getOne(schema.key).then((res) => {
      console.clear();
      this.setState({
        schema: res.data
      });
      // unmarshal the JSON document into the canvas
      // (load)
      const reader = new Reader(this.canvas);
      if (res.data.json) {
        reader.loadJSONAndSchema(this.canvas, res.data.json);
      }

      for (const figure of this.canvas.getFigures().data) {
        this._updateFigureName(figure);

      }

      for (const line of this.canvas.getLines().data) {
        this._updateLineName(line);

      }

      this.setState({
        canvas: this.canvas
      }, () => {
        for (const figure of this.state.canvas.figures.data) {
          if (figure.userData.root) {
            figure.setBackgroundColor('#fcdb03');
          }
          if (figure.NAME === NODE_LIST.nodeAnswerList.name || figure.NAME === NODE_LIST.nodeAnswerBool.name) {
            for (const branche of figure.userData.branches) {
              for (const line of this.state.canvas.lines.data) {
                if (line.id === branche.connectionId) {
                  switch (branche.answer) {
                    case true:
                      this._setLineLabel(line, 'true');
                      break;
                    case false:
                      this._setLineLabel(line, 'false');
                      break;
                    case null:
                      this._setLineLabel(line, 'null');
                      break;
                    default:
                      this._setLineLabel(line, branche.answer);
                      break;
                  }
                }
              }
            }
          }
          else if (figure.NAME === NODE_LIST.nodeRange.name) {
            for (const branche of figure.userData.branches) {
              for (const line of this.state.canvas.lines.data) {
                if (line.id === branche.connectionId) {
                  this._setLineLabel(line, `${branche.if.label} ${branche.than}`);
                }
              }
            }
          }
        }
      });
    });


    this.state.canvas.setZoom(this.state.currentZoom, true);
  }

  private _setupCanvas() {
    // Show grid
    this.canvas.installEditPolicy(new draw2d.policy.canvas.ShowGridEditPolicy());

    this.canvas.installEditPolicy(new ConnectionCreatePolicy(), this.canvas);

    this.canvas.installEditPolicy(new draw2d.policy.canvas.FadeoutDecorationPolicy());

    this.canvas.setScrollArea('html');

    this.canvas.installEditPolicy(new draw2d.policy.canvas.WheelZoomPolicy());

    this.canvas.on('select', (e: any) => {
      if (e.getSelection().all.data.length === 1) {
        this.selection = e.getPrimarySelection();
        this.setState({
          showEditPanel: true
        });
        this.setState({
          selectedShape: this.selection,
          selection: this.selection
        });
      }
      else {
        this.setState({
          showEditPanel: false
        });
      }
    });

    this.canvas.on('unselect', () => {
      this.setState({
        showEditPanel: false
      });
      this.setState({
        selectedShape: null,
        currentName: null
      });
    });

    this.canvas.getCommandStack().addEventListener((e) => {
      if (e.action) {
        this.setState({
          changes: true
        });
      }
      if (e.action === "POST_EXECUTE" && e.command.label === "Connect Ports") {
        if (e.command.source.parent.NAME === 'node_answer_bool' && e.command.source.parent.userData.branches.length === 3) {
          this.toast?.show({
            severity: SeverityEnum.ERROR,
            detail: 'Maximum 3 links out of an answer_bool node!',
          });
          const command = new draw2d.command.CommandDelete(e.command.connection);
          this.state.canvas.getCommandStack().execute(command);
        }
        else if (e.command.source.parent.NAME === 'node_min' || e.command.source.parent.NAME === 'node_max') {
          e.command.source.parent.userData.nodes.push({
            nodeName: e.command.target.parent.userData.name,
            id: e.command.connection.id
          });
        }
        else {
          if (e.command.source.parent.userData.branches) {
            e.command.source.parent.userData.branches.push({
              nodeName: e.command.target.parent.userData.name,
              answer: e.command.source.parent.NAME === 'node_answer_bool' ? null : [],
              id: e.command.target.parent.id,
              connectionId: e.command.connection.id
            });
          }
        }
      }
      if (e.action === "POST_EXECUTE" && e.command.label === "Delete Shape" && e.command.figure?.sourcePort?.parent.NAME === "node_answer_list") {
        let i = 0;
        for (const branche of e.command.figure.sourcePort.parent.userData.branches) {
          if (branche.nodeName === e.command.figure.targetPort.parent.userData.name) {
            e.command.figure.sourcePort.parent.userData.branches.splice(i, 1);
          }
          i++;
        }
      }
      if (e.action === "POST_EXECUTE" && e.command.label === "Delete Shape" && (e.command.figure?.sourcePort?.parent.NAME === "node_multiple_answer_list" ||
        e.command.figure?.sourcePort?.parent.NAME === "node_answer_bool"
      )) {
        let i = 0;
        for (const branche of e.command.figure.sourcePort.parent.userData.branches) {
          if (branche.nodeName === e.command.figure.targetPort.parent.userData.name) {
            e.command.figure.sourcePort.parent.userData.branches.splice(i, 1);
          }
          i++;
        }
      }

      if (e.action === "POST_EXECUTE" && e.command.label === "Delete Shape" && e.command.connections?.data.length > 0) {
        for (const branche of e.command.connections.data) {
          let j = 0;
          let k = 0;
          if (branche.sourcePort.parent.userData.branches) {
            for (const brancheParent of branche.sourcePort.parent.userData.branches) {
              if (e.command.figure.id === brancheParent.id) {
                branche.sourcePort.parent.userData.branches.splice(j, 1);
              }
              j++;
            }
          }
          if (branche.sourcePort.parent.userData.nodes) {
            for (const brancheParent of branche.sourcePort.parent.userData.nodes) {
              if (branche.id === brancheParent.id) {
                branche.sourcePort.parent.userData.nodes.splice(k, 1);
              }
              k++;
            }
          }

        }
      }
      if (e.action === "POST_EXECUTE" && e.command.label === "Delete Shape" && (e.command.figure.NAME === "Transition" || e.command.figure.NAME === "transition_concat")) {
        let k = 0;
        if (e.command.figure.sourcePort.parent.userData.nodes) {
          for (const nodeParent of e.command.figure.sourcePort.parent.userData.nodes) {
            if (nodeParent.id === e.command.figure.id) {
              e.command.figure.sourcePort.parent.userData.nodes.splice(k, 1);
            }
            k++;
          }
        }
      }
      if (e.action === "POST_EXECUTE" && e.command.label === "Connect Ports" && e.command.connection?.NAME === "Transition") {
        if (e.command.connection && (e.command.connection.sourcePort?.parent?.NAME === "node_max" ||
          e.command.connection.sourcePort?.parent?.NAME === "node_min")) {
          e.command.connection.NAME = "transition_concat";
          // add bar decorator
          let bar = new draw2d2.decoration.connection.BarDecorator();
          bar.setDimension(50, 30);
          bar.setBackgroundColor('#e98b3a');
          e.command.connection.setSourceDecorator(bar);
          e.command.connection.setColor('#e98b3a');
          let bar2 = new draw2d2.decoration.connection.BarDecorator();
          bar2.setDimension(0, 0);
          e.command.connection.setTargetDecorator(bar2);
        }
      }
      if (e.action === "POST_EXECUTE" && e.command.label === "Connect Ports" && e.command.connection?.NAME === "Transition") {
        if (e.command.connection && (e.command.connection.sourcePort?.parent?.NAME === "node_answer_bool")) {
          // remove old table
          if (e.command.connection.tableInfo) {
            e.command.connection.remove(e.command.connection.tableInfo);
          }

          e.command.connection.tableInfo = new draw2d.shape.layout.TableLayout({
            stroke: 0,
            resizeable: true,
            bgColor: '#FFFFFF',
          });
          let header: any[] = [];

          header.push('null');

          e.command.connection.tableInfo.addRow(...header);
          e.command.connection.tableInfo.setPadding(0);

          e.command.connection.add(e.command.connection.tableInfo, new draw2d.layout.locator.PolylineMidpointLocator());
        }
      }
    });
  }

  private _getDatas() {

        this.formService.getQuestionsByType([]).then((res) => {
            if (res.data) {
                // @ts-ignore
                this.setState({
                    // @ts-ignore
                    questionList: res.data
                })
            }
        });

        this.decisionTreeService.getAllDecisionTrees().then((res) => {
            if (res.data) {
                // @ts-ignore
                this.setState({
                    // @ts-ignore
                    decisionTreeList: res.data
                })
            }
        });

        this.formService.getQuestionsByType(['bool']).then((res) => {
            if (res.data) {
                // @ts-ignore
                this.setState({
                    // @ts-ignore
                    questionListAnswerBool: res.data
                })
            }
        });

        this.formService.getQuestionsByType([]).then((res) => {
            if (res.data) {
                // @ts-ignore
                this.setState({
                    // @ts-ignore
                    questionListResult: res.data
                })
            }
        });

    this.scriptService.getMany().then((res) => {
      if (res.data) {
        this.setState({
          scriptList: res.data
        });
      }
    });
  }

  private _getQuestionsByType(type: string[]): { type: string, name: string, label: string, values: string[] }[] {
    let questions = [];
    for (const question of this.state.questionList) {
      if (type.length === 0) {
        // @ts-ignore
        questions.push(question);
      }
      if (type.includes(question.type)) {
        // @ts-ignore
        questions.push(question);
      }
    }
    return questions;
  }

  private _setupOnDropAction() {
    this.canvas.onDrop = (droppedDomNode, x, y) => {
      if (this.state.schema) {
        this.setState({
          changes: true
        });
        let isRoot = true;
        if (this.state.canvas.figures.data.length) {
          isRoot = false;
        }
        switch (droppedDomNode[0].dataset.shape) {
          case 'node':
            const node = new NodeAnswerList(x, y);
            this.state.canvas.add(node);
            break;
          case 'node_answer_bool':
            const node_answer_bool = new NodeAnswerBool(x, y);
            this.state.canvas.add(node_answer_bool);
            break;
          case 'node_answer_list':
            const node_answer_list = new NodeAnswerList(x, y);
            this.state.canvas.add(node_answer_list);
            break;
          case 'node_min':
            const node_min = new NodeMin(x, y);
            this.state.canvas.add(node_min);
            break;
          case 'node_max':
            const node_max = new NodeMax(x, y);
            this.state.canvas.add(node_max);
            break;
          case 'node_range':
            const node_range = new NodeRange(x, y);
            this.state.canvas.add(node_range);
            break;
          case 'node_computation':
            const node_computation = new NodeComputation(x, y);
            this.state.canvas.add(node_computation);
            break;
          case 'node_question_result':
            const node_question_result = new NodeQuestionResult(x, y);
            this.state.canvas.add(node_question_result);
            break;
          case 'node_result':
            const node_result = new NodeResult(x, y);
            this.state.canvas.add(node_result);
            break;
          case 'node_script':
            const node_script = new NodeScript(x, y);
            this.state.canvas.add(node_script);
            break;
          case 'node_concat':
            const node_concat = new NodeConcat(x, y);
            this.state.canvas.add(node_concat);
            break;
          case 'node_multiple_answer_list':
            const node_multiple_answer_list = new NodeMultipleAnswerList(x, y);
            this.state.canvas.add(node_multiple_answer_list);
            break;
        }
        if (isRoot) {
          const canvas = this.state.canvas;
          canvas.figures.data[0].userData['root'] = true;
          canvas.figures.data[0].setBackgroundColor('#fcdb03');
          this.setState({
            canvas
          });
        }
      }
      else {
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: 'Select a schema first!',
        });
      }
    };
  }

  changeZoom(factor: number) {
    if (this.state.currentZoom + factor > 0.1 && this.state.currentZoom + factor < 5) {
      this.state.canvas.setZoom(this.state.currentZoom + factor, true);
      this.setState({
        currentZoom: this.state.currentZoom + factor
      });
    }
  }

  /**
   * Reset zoom to factor 1
   */
  resetZoom() {
    this.state.canvas.setZoom(1.5, true);
    this.setState({
      currentZoom: 1.5
    });
  }

  private _updateFigureName(figure: any, text ?: string) {
    const shape = this.canvas.getFigure(figure['id'].toString());
    figure.setLabel((figure.NAME === "node_computation" && shape.userData.operator) ? this._getComputationName(shape.userData.operator.name) : (text ?? shape.userData.name));
    //update branches name
    let canvas = this.state.canvas;
    for (const figure of canvas.figures.data) {
      if (figure.userData.branches) {
        for (const branche of figure.userData.branches) {
          if (branche.id === shape.id && branche.nodeName !== undefined && branche.nodeName !== text) {
            branche.nodeName = text ?? branche.nodeName;
            this.setState({
              canvas
            });
          }
        }
      }
      if (figure.userData.nodes) {
        for (const node of figure.userData.nodes) {
          if (node.id === shape.id && node.nodeName !== undefined && node.nodeName !== text) {
            node.nodeName = text ?? node.nodeName;
            this.setState({
              canvas
            });
          }
        }
      }
      if (figure.NAME === 'node_computation') {
        for (const figure of canvas.figures.data) {
          for (const [key] of Object.entries(figure.userData)) {
            if (figure.userData[key] && figure.userData[key].value?.id === shape.id) {
              figure.userData[key].value.name = shape.userData.name;
              figure.userData[key].value.label = shape.userData.name;
              this.setState({
                canvas
              });
            }
          }
        }
      }
    }
  }

  private _setLineLabel(line: any, label: string) {
    if (Array.isArray(label)) {
      if (label.length === 0) {
        return;
      }

      label = label.map(x => x.label).join(';');
    }

    // remove old table
    if (line.tableInfo) {
      line.remove(line.tableInfo);
    }

    line.tableInfo = new draw2d.shape.layout.TableLayout({
      stroke: 0,
      resizeable: true,
      bgColor: '#FFFFFF',
    });
    let header: any[] = [];
    header.push(label);
    line.tableInfo.addRow(...header);
    line.tableInfo.setPadding(0);

    line.add(line.tableInfo, new draw2d.layout.locator.PolylineMidpointLocator());
  }

  private _getComputationName(name: string) {
    switch (name) {
      case '-':
        return 'sub';
      case '+':
        return 'add';
      case '/':
        return 'div';
      case '*':
        return 'mult';
    }
  }

  private _updateLineName(line: any, text ?: string) {
    const transition = this.canvas.getLine(line['id'].toString());
    try {
      transition.setLabel(text ?? line.userData.name);
    } catch (e) {
      console.log(e);
    }
  }

  private _getShapeText(shape: any): string {
    if (shape && shape.children.data.length > 0) {
      if (shape.children.data[0]?.figure.text) {
        return shape.children.data[0]?.figure.text;
      }
      else {
        return this._getShapeText(shape.children.data[0].figure);
      }
    }
    else {
      return '';
    }
  }

  private _refreshSchemas(newSchemaName: string, key ?: string) {
    let schema;
    if (newSchemaName) {
      schema = {
        ...this.state.schema,
        name: newSchemaName
      };
      this.setState({
        schema
      });
    }
    else {
      this.state.canvas.clear();
      schema = null;
    }
    if (!key) {
      this.setState({
        schema: null
      });
    }
    this.setState({
      currentName: newSchemaName,
      currentKey: key,
      refreshSchemasList: !this.state.refreshSchemasList
    });
  }

  private _renderDialogFooter() {
    return (
      <div>
        <Button
          label="Annuler"
          icon="pi pi-times"
          onClick={() => this.setState({ showNewTreeDialog: false })}
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          className="p-button-danger"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          onClick={() => {
            this._createNewTree();
          }}
          className="p-button-danger"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
        />
      </div>
    );
  }

  private _createNewTree() {
    const datas = {
      key: this.state.currentKey,
      name: this.state.currentName,
    };
    this.decisionTreeEditorService.createSchema(datas).then((res) => {
      if (res) {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: 'Decision tree successfully created !',
        });
        const schema = {
          key: res.data.key,
          name: res.data.name,
          json: res.data.json,
        };
        this.state.canvas.clear();
        this.setState({
          currentName: null,
          currentKey: null,
          schema,
          showNewTreeDialog: false,
        });
        this._refreshSchemas(this.state.currentName, this.state.currentKey);
      }
    })
      .catch((err) => {
        if (err.response) {
          this.toast?.show({
            severity: SeverityEnum.ERROR,
            detail: err.response.data.message,
          });
        }
      });
  }

  private _deleteShape() {
    const shape = this.state.canvas.getPrimarySelection();
    const command = new draw2d.command.CommandDelete(shape);
    this.state.canvas.getCommandStack().execute(command);
  }

  private _setSelectionUserDataValue(key: string, value: any) {
    let selection = this.state.selection;
    selection.userData[key] = value;
    this.setState({
      selection,
      changes: true
    });
  }

  private _getRootCheckboxContent() {
    return (
      <div>
        <Checkbox inputId=" root" checked={this.state.selection.userData.root} onChange={e => {
          this._setSelectionUserDataValue('root', e.checked);
          this.state.selection.setBackgroundColor(e.checked ? '#fcdb03' : "rgba(209, 209, 209,1)");
        }}/>
        <label htmlFor="root">root</label>
      </div>
    );
  }

  private _getQuestionDecisionTreeSelectContent(type: string[]) {
    return (
      <div>
        <div className="field-radiobutton">
          <RadioButton inputId="question" name="Question" value="question" onChange={(e) => {
            this._setSelectionUserDataValue('evaluatedElementRadio', e.value);
          }} checked={this.state.selection.userData.evaluatedElementRadio === 'question'}/>
          <label htmlFor="question">Question</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton inputId="decision_tree" name="Decision tree" value="decision_tree" onChange={(e) => {
            this._setSelectionUserDataValue('evaluatedElementRadio', e.value);
          }} checked={this.state.selection.userData.evaluatedElementRadio === 'decision_tree'}/>
          <label htmlFor="decision_tree">Decision tree</label>
        </div>
        <div className="dropdownQuestions">
          <Dropdown value={this.state.selection.userData.evaluatedElement}
                    options={
                      (this.state.selection.userData.evaluatedElementRadio === 'decision_tree') ?
                        this.state.decisionTreeList :
                        this._getQuestionsByType(type)
                    }
                    onChange={(e) => {
                      this._setSelectionUserDataValue('evaluatedElement', e.value);
                    }}
                    filter
                    optionLabel="name"
                    placeholder={(this.state.selection.userData.evaluatedElementRadio === 'decision_tree') ? "Select a decision tree" : "Select a question"}/>
        </div>
      </div>
    );
  }

  private _getNodeAnswerListContent() {
    let content: any[] = [];

    content.push(
      <div className="branch-configuration">
        <h3>{this.state.selection?.NAME + ' configuration: ' ?? 'Configuration'}</h3>
        <h5>Evaluated element:</h5>
        <div className="field-checkbox">
          {this._getRootCheckboxContent()}
        </div>
        <div className="field-radiobutton">
          <RadioButton inputId="question" name="Question" value="question" onChange={(e) => {
            //Reset branches value on change
            this.state.selection.userData.branches.forEach((branche) => {
              branche.answer = [];
            });
            this._setSelectionUserDataValue('evaluatedElementRadio', e.value);
          }} checked={this.state.selection.userData.evaluatedElementRadio === 'question'}/>
          <label htmlFor="question">Question</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton inputId="decision_tree" name="Decision tree" value="decision_tree" onChange={(e) => {
            //Reset branches value on change
            this.state.selection.userData.branches.forEach((branche) => {
              branche.answer = [];
            });
            this._setSelectionUserDataValue('evaluatedElementRadio', e.value);
          }} checked={this.state.selection.userData.evaluatedElementRadio === 'decision_tree'}/>
          <label htmlFor="decision_tree">Decision tree</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton inputId="script" name="Script" value="script" onChange={(e) => {
            //Reset branches value on change
            this.state.selection.userData.branches.forEach((branche) => {
              branche.answer = [];
            });
            this._setSelectionUserDataValue('evaluatedElementRadio', e.value);
          }} checked={this.state.selection.userData.evaluatedElementRadio === 'script'}/>
          <label htmlFor="script">Script</label>
        </div>
      </div>);
    let placeHolder = '';
    let option: any = null;
    switch (this.state.selection.userData.evaluatedElementRadio) {
      case 'decision_tree':
        placeHolder = "Select a decision tree";
        option = this.state.decisionTreeList;
        break;
      case 'question':
        placeHolder = "Select a question";
        option = this._getQuestionsByType(['radio', 'dropdown', 'radio_image', 'matrix', 'bool']);
        break;
      case 'script':
        placeHolder = "Select a script";
        const scripts = [] as any;
        for (const script of this.state.scriptList) {
          scripts.push({ name: script.fileName, label: script.fileName });
        }
        option = scripts;
        break;

    }
    if (this.state.selection.userData.evaluatedElementRadio) {
      content.push(
        <div className="dropdownQuestions">
          <Dropdown value={this.state.selection.userData.evaluatedElement}
                    options={option}
                    onChange={(e) => {
                      //Reset branches value on change
                      this.state.selection.userData.branches.forEach((branche) => {
                        branche.answer = [];
                      });
                      this._setSelectionUserDataValue('evaluatedElement', e.value);
                    }}
                    filter
                    optionLabel="name"
                    placeholder={placeHolder}/>
        </div>
      );
    }

    const tableBody: any[] = [];
    let values: any = [];

    for (const question of this._getQuestionsByType(['radio', 'dropdown', 'radio_image', 'matrix', 'bool'])) {
      if (question.name === this.state.selection.userData.evaluatedElement?.name) {
        if (question.type === QuestionType.BOOL) {
          values.push({ name: 'true', label: 'true' });
          values.push({ name: 'false', label: 'false' });
          values.push({ name: 'null', label: 'no_answer' });
        }
        else {
          for (const value of question.values) {
            values.push({ name: value, label: value });
          }
          values.push({ name: 'null', label: 'no_answer' });
        }
      }
    }
    for (const branche of this.state.selection.userData.branches) {
      const inputText = (
        <InputText type="text"
                   className="p-inputtext-sm block mb-2"
                   placeholder="Enter value(s)"
                   value={branche.answer}
                   onChange={(e: any) => {
                     const selection = this.state.selection;
                     for (const el of selection.userData.branches) {
                       if (el.id === branche.id) {
                         el.answer = e.target.value;
                       }
                       for (const line of this.state.canvas.lines.data) {
                         if (line.id === branche.connectionId) {
                           this._setLineLabel(line, e.target.value);
                         }
                       }
                     }
                     this.setState({
                       selection
                     }, () => this.setState({
                       changes: true
                     }));
                   }}
        />
      );

      const inputTextScript = (
        <InputText type="text"
                   className="p-inputtext-sm block mb-2"
                   placeholder="Enter value"
                   value={branche.answer}
                   onChange={(e: any) => {
                     const selection = this.state.selection;
                     for (const el of selection.userData.branches) {
                       if (el.id === branche.id) {
                         el.answer = e.target.value;
                       }
                       for (const line of this.state.canvas.lines.data) {
                         if (line.id === branche.connectionId) {
                           this._setLineLabel(line, e.target.value);
                         }
                       }
                     }
                     this.setState({
                       selection
                     }, () => this.setState({
                       changes: true
                     }));
                   }}
        />
      );

      const multiSelect = (
        <MultiSelect
          value={branche.answer}
          options={values}
          onChange={(e) => {
            const selection = this.state.selection;
            for (const el of selection.userData.branches) {
              if (el.id === branche.id) {
                el.answer = e.value;
              }
              for (const line of this.state.canvas.lines.data) {
                if (line.id === branche.connectionId) {
                  const answers: string[] = [];
                  e.value.forEach((x) => answers.push(x.label));
                  this._setLineLabel(line, answers.join(';') ?? '');
                }
              }
            }
            this.setState({
              selection
            }, () => this.setState({
              changes: true
            }));
          }}
          optionLabel="label"
          placeholder="Select a value"
          // @ts-ignore
          display="chip"/>
      );

      tableBody.push(
        <tr>
          <td>
            {
              this.state.selection.userData.evaluatedElementRadio === 'decision_tree' ? inputText :
                (this.state.selection.userData.evaluatedElementRadio === 'script' ? inputTextScript : multiSelect)
            }
          </td>
          <td>{branche.nodeName}</td>
        </tr>
      );
    }

    if (this.state.selection.userData.evaluatedElementRadio === 'decision_tree') {
      content.push(<p>Separate different element using " ; "</p>);
    }

    content.push(
      <div>
        <table>
          <thead>
          <tr>
            <th>if</th>
            <th>to</th>
          </tr>
          </thead>
          <tbody>
          {tableBody}
          </tbody>
        </table>
      </div>
    );

    return content;
  }

  private _getNodeMultipleAnswerListContent() {
    let content: any[] = [];

    content.push(
      <div className="branch-configuration">
        <h3>{this.state.selection?.NAME + ' configuration: ' ?? 'Configuration'}</h3>
        <h5>Evaluated element:</h5>
        <div className="field-checkbox">
          {this._getRootCheckboxContent()}
        </div>
      </div>
    );
    content.push(
      <div className="dropdownQuestions">
        <label htmlFor="node-result-value">Question: </label>
        <Dropdown value={this.state.selection.userData.evaluatedElement}
                  options={
                    this._getQuestionsByType(['checkbox'])
                  }
                  onChange={(e) => {
                    //Reset branches value on change
                    this.state.selection.userData.branches.forEach((branche) => {
                      branche.answer = [];
                    });
                    this._setSelectionUserDataValue('evaluatedElement', e.value);
                  }}
                  filter
                  optionLabel="name"
                  appendTo={document.body}
                  placeholder={"Select a question"}/>
      </div>
    );

    const tableBody: any[] = [];
    let values: any = [];

    for (const question of this._getQuestionsByType(['checkbox'])) {
      if (question.name === this.state.selection.userData.evaluatedElement?.name) {
        for (const value of question.values) {
          values.push({ name: value, label: value });
        }
        values.push({ name: 'null', label: 'null' });
      }
    }
    const selection = this.state.selection;
    for (const branche of selection.userData.branches) {
      const operator = (
        <Dropdown value={branche.operator}
                  options={
                    [
                      { name: OperatorType.STRICTLY_EQUAL, label: OperatorType.STRICTLY_EQUAL },
                      { name: OperatorType.STRICTLY_INCLUDED, label: OperatorType.STRICTLY_INCLUDED },
                      { name: OperatorType.STRICTLY_INCLUDES, label: OperatorType.STRICTLY_INCLUDES }
                    ]
                  }
                  onChange={(e) => {
                    branche.operator = e.target.value;
                    this.setState({
                      selection
                    });
                  }}
                  filter
                  optionLabel="name"
                  appendTo={document.body}
                  placeholder={"Select a question"}/>
      );
      const multiSelect = (
        <MultiSelect
          value={branche.answer}
          options={values}
          onChange={(e) => {
            const selection = this.state.selection;
            for (const el of selection.userData.branches) {
              if (el.id === branche.id) {
                el.answer = e.value;
              }
            }
            this.setState({
              selection
            }, () => this.setState({
              changes: true
            }));
          }}
          optionLabel="name"
          placeholder="Select a value"
          appendTo={document.body}
          // @ts-ignore
          display="chip"/>
      );

      tableBody.push(
        <tr>
          <td>{multiSelect}</td>
          <td>{operator}</td>
          <td>{branche.nodeName}</td>
        </tr>
      );
    }

    if (this.state.selection.userData.evaluatedElementRadio === 'decision_tree') {
      content.push(<p>Separate different element using " ; "</p>);
    }

    content.push(
      <div>
        <table className="nodeRangeTable nodeAnswerList">
          <thead>
          <tr>
            <th>if</th>
            <th>Operator</th>
            <th>to</th>
          </tr>
          </thead>
          <tbody>
          {tableBody}
          </tbody>
        </table>
      </div>
    );

    return content;
  }

  private _getNodeAnswerBoolContent() {
    let content: any[] = [];

    content.push(
      <div>
        <h3>{this.state.selection?.NAME + ' configuration: ' ?? 'Configuration'}</h3>
        <div className="field-checkbox">
          {this._getRootCheckboxContent()}
        </div>
        {this._getQuestionDecisionTreeSelectContent(['checkbox'])}
      </div>
    );
    const tableBody: any[] = [];
    for (const branche of this.state.selection.userData.branches) {

      const triStateCheckbox = (
        <div>
          <TriStateCheckbox
            value={branche.answer ?? null}
            onChange={(e: any) => {
              const selection = this.state.selection;
              for (const el of selection.userData.branches) {
                if (el.id === branche.id) {
                  el.answer = e.value;
                }
                for (const line of this.state.canvas.lines.data) {
                  if (line.id === branche.connectionId) {
                    switch (branche.answer) {
                      case true:
                        this._setLineLabel(line, 'true');
                        break;
                      case false:
                        this._setLineLabel(line, 'false');
                        break;
                      default:
                        this._setLineLabel(line, 'null');
                        break;
                    }
                  }
                }
              }
              this.setState({
                selection,
              }, () => this.setState({
                changes: true
              }));
            }}
          />
          <label>{String(branche.answer)}</label>
        </div>
      );

      tableBody.push(
        <tr>
          <td>{branche.nodeName}</td>
          <td>
            {
              triStateCheckbox
            }
          </td>
        </tr>
      );
    }

    if (this.state.selection.userData.branches.length > 0) {
      content.push(
        <div>
          <table>
            <thead>
            <tr>
              <th>to</th>
              <th>isTrue</th>
            </tr>
            </thead>
            <tbody>
            {tableBody}
            </tbody>
          </table>
        </div>
      );
    }

    return content;
  }

  private _getNodeResultContent() {
    let content: any[] = [];

    content.push(
      <div>
        <h3>{this.state.selection?.NAME + ' configuration: ' ?? 'Configuration'}</h3>
        <div className="field-checkbox">
          {this._getRootCheckboxContent()}
        </div>
        <label htmlFor="node-result-value">Value type: </label>
        <Dropdown value={this.state.selection.userData.value?.type}
          // @ts-ignore
                  options={[
                    { name: 'string', label: 'string' },
                    { name: 'boolean', label: 'boolean' },
                    { name: 'number', label: 'number' },
                  ]}
                  onChange={(e) => {
                    const selection = this.state.selection;
                    selection.userData.value.type = e.target.value;
                    this.setState({
                      selection
                    }, () => this.setState({
                      changes: true
                    }));
                  }}
                  id="node-result-result"
                  appendTo={document.body}
                  placeholder={"Select a value"}/>
        {this._getNodeResultContentByType()}
      </div>
    );

    if (this.state.selection.userData.question) {
      let values: any[] = [];
      for (const value of this.state.selection.userData.question.values) {
        values.push({ name: value, label: value });
      }
      content.push(
        <div>
          <label htmlFor="node-result-value">Value:</label>
          <Dropdown value={this.state.selection.userData.value}
            // @ts-ignore
                    options={values}
                    onChange={(e) => {
                      this._setSelectionUserDataValue('value', e.value);
                    }}
                    id="node-result-result"
                    placeholder={"Select a value"}/>
        </div>
      );
    }

    return content;
  }

  private _getNodeResultContentByType() {
    switch (this.state.selection.userData.value.type?.name) {
      case 'string':
        return (
          <div>
            <label htmlFor="node-result-question">Value: </label>
            <InputText type="text"
                       className="p-inputtext-sm block mb-2"
                       placeholder="Enter value"
                       value={this.state.selection.userData.value.value}
                       onChange={(e: any) => {
                         const selection = this.state.selection;
                         selection.userData.value.value = e.target.value;
                         this.setState({
                           selection
                         }, () => this.setState({
                           changes: true
                         }));
                       }}
            />
          </div>
        );
      case 'number':
        return (
          <div>
            <label htmlFor="node-result-question">Value: </label>
            <InputNumber className="p-inputtext-sm block mb-2"
                         placeholder="Enter value"
                         value={this.state.selection.userData.value.value}
                         onChange={(e: any) => {
                           const selection = this.state.selection;
                           selection.userData.value.value = e.value;
                           this.setState({
                             selection
                           }, () => this.setState({
                             changes: true
                           }));
                         }}
            />
          </div>
        );
      case 'boolean':
        return (
          <div>
            <label htmlFor="node-result-question">Value: </label>
            <Dropdown value={this.state.selection.userData.value?.value}
              // @ts-ignore
                      options={[
                        { name: 'true', label: 'true' },
                        { name: 'false', label: 'false' },
                        { name: 'null', label: 'null' },
                      ]}
                      onChange={(e) => {
                        const selection = this.state.selection;
                        selection.userData.value.value = e.target.value;
                        this.setState({
                          selection
                        }, () => this.setState({
                          changes: true
                        }));
                      }}
                      id="node-result-result"
                      appendTo={document.body}
                      placeholder={"Select a value"}/>
          </div>
        );
    }
  }

  private _getNodeRangeContent() {

    let content: any[] = [];
    let nodes: any [] = [];

    for (const figure of this.state.canvas.figures.data) {
      if (figure.id === this.state.selection.id) {
        continue;
      }
      nodes.push({
        name: figure.userData.name,
        label: figure.userData.name,
        id: figure.id
      });
    }

    content.push(
      <div className="branch-configuration">
        <h3>{this.state.selection?.NAME + ' configuration: ' ?? 'Configuration'}</h3>
        <h5>Evaluated element:</h5>
        <div className="field-checkbox">
          {this._getRootCheckboxContent()}
        </div>
        <div className="field-radiobutton">
          <RadioButton inputId="question" name="Question" value="question" onChange={(e) => {
            this._setSelectionUserDataValue('evaluatedElementRadio', e.value);
          }} checked={this.state.selection.userData.evaluatedElementRadio === 'question'}/>
          <label htmlFor="question">Question</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton inputId="decision_tree" name="Decision tree" value="decision_tree" onChange={(e) => {
            this._setSelectionUserDataValue('evaluatedElementRadio', e.value);
          }} checked={this.state.selection.userData.evaluatedElementRadio === 'decision_tree'}/>
          <label htmlFor="decision_tree">Decision tree</label>
        </div>
        <div className="field-radiobutton">
          <RadioButton inputId="script" name="Script" value="script" onChange={(e) => {
            this._setSelectionUserDataValue('evaluatedElementRadio', e.value);
          }} checked={this.state.selection.userData.evaluatedElementRadio === 'script'}/>
          <label htmlFor="script">Script</label>
        </div>
      </div>);
    let placeHolder = '';
    let option: any = null;
    switch (this.state.selection.userData.evaluatedElementRadio) {
      case 'decision_tree':
        placeHolder = "Select a decision tree";
        option = this.state.decisionTreeList;
        break;
      case 'question':
        placeHolder = "Select a question";
        option = this._getQuestionsByType(['number']);
        break;
      case 'script':
        placeHolder = "Select a script";
        const scripts = [] as any;
        for (const script of this.state.scriptList) {
          scripts.push({ name: script.fileName, label: script.fileName });
        }
        option = scripts;
        break;

    }

    if (this.state.selection.userData.evaluatedElementRadio) {
      content.push(
        <div className="dropdownQuestions">
          <Dropdown value={this.state.selection.userData.evaluatedElement}
                    options={option}
                    onChange={(e) => {
                      this._setSelectionUserDataValue('evaluatedElement', e.value);
                    }}
                    filter
                    optionLabel="name"
                    placeholder={placeHolder}/>
        </div>
      );
    }
    content.push(
      <div className="addValueButton">
        <Button
          label="Add value"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a', color: 'black' }}
          onClick={() => {
            const selection = this.state.selection;
            selection.userData.branches.push({ type: null, target: null, uuid: this._uuidv4() });
            this.setState({
              selection
            });
          }}
          className="p-button-secondary"/>
      </div>
    );

    const tableBody: any[] = [];
    const selection = this.state.selection;
    for (const branche of selection.userData.branches) {
      let conditions = [
        { name: OperatorType.NOT_EQUAL, label: '≠' },
        { name: OperatorType.EQUAL, label: '=' },
        { name: OperatorType.GREATER_THAN, label: '>' },
        { name: OperatorType.GREATER_THAN_OR_EQUAL_TO, label: '≥' },
        { name: OperatorType.LOWER_THAN_OR_EQUAL_TO, label: '≤' },
        { name: OperatorType.LOWER_THAN, label: '<' }
      ];
      const ifDropdown = (
        <Dropdown value={branche.if}
                  options={conditions}
                  onChange={(e) => {
                    const selection = this.state.selection;
                    for (const el of selection.userData.branches) {
                      if (el.id === branche.id) {
                        el.if = e.value;
                      }
                      for (const line of this.state.canvas.lines.data) {
                        if (line.id === branche.connectionId) {
                          this._setLineLabel(line, `${e.value.label} ${branche.than ?? ''}`);
                        }
                      }
                    }
                    this.setState({
                      selection
                    }, () => this.setState({
                      changes: true
                    }));
                  }}
                  filter
                  appendTo={document.body}
                  optionLabel="label"
                  placeholder="Select"/>
      );

      const thanInputNumber = (
        <InputText type="number"
                   className="p-inputtext-sm block mb-2"
                   placeholder="Enter value(s)"
                   value={parseInt(branche.than)}
                   onChange={(e: any) => {
                     const selection = this.state.selection;
                     for (const el of selection.userData.branches) {
                       if (el.id === branche.id) {
                         el.than = e.target.value;
                       }
                       for (const line of this.state.canvas.lines.data) {
                         if (line.id === branche.connectionId) {
                           this._setLineLabel(line, `${branche.if.label} ${e.target.value}`);
                         }
                       }
                     }
                     this.setState({
                       selection
                     }, () => this.setState({
                       changes: true
                     }));
                   }}
        />
      );

      const nodeList = (
        <div>
          <Dropdown
            id="node-question-result-value"
            value={branche.target}
            options={nodes}
            onChange={(e) => {
              for (const port of selection.hybridPorts.data) {
                if (port.name === 'down') {
                  for (const connection of port.connections.data) {
                    if (connection.id === branche.id) {
                      const command = new draw2d.command.CommandDelete(connection);
                      this.state.canvas.getCommandStack().execute(command);
                    }
                  }
                }
              }

              branche.target = e.value;
              for (const figure of this.state.canvas.figures.data) {
                if (figure.id === e.value.id) {
                  let nodeT = new Transition(selection, figure);
                  this.state.canvas.add(nodeT);
                  branche.id = nodeT.id;
                  branche.connectionId = nodeT.id;
                  for (const line of this.state.canvas.lines.data) {
                    if (line.id === branche.connectionId) {
                      this._setLineLabel(line, `${branche.if.label} ${branche.than}`);
                    }
                  }
                }
              }
              this.setState({
                selection
              }, () => this.setState({
                changes: true
              }));
            }}
            filter
            optionLabel="name"
            appendTo={document.body}
            placeholder={"Node"}/>
        </div>
      );

      tableBody.push(
        <tr>
          <td style={{ maxWidth: '100px' }}>{ifDropdown}</td>
          <td>{thanInputNumber}</td>
          <td>{nodeList}</td>
          <td>
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-outlined"
              onClick={() => {
                for (const port of selection.hybridPorts.data) {
                  if (port.name === 'down') {
                    for (const connection of port.connections.data) {
                      if (connection.id === branche.id) {
                        const command = new draw2d.command.CommandDelete(connection);
                        this.state.canvas.getCommandStack().execute(command);
                      }
                    }
                  }
                }
                for (const i in selection.userData.branches) {
                  if (selection.userData.branches[i].uuid === branche.uuid) {
                    selection.userData.branches.splice(i, 1);
                  }
                }
                this.setState({
                  selection
                }, () => this.setState({
                  changes: true
                }));
              }}
            />
          </td>
        </tr>
      );
    }

    content.push(
      <div>
        <table className="nodeRangeTable">
          <thead>
          <tr>
            <th style={{ maxWidth: '100px' }}>if</th>
            <th>than</th>
            <th>to</th>
          </tr>
          </thead>
          <tbody>
          {tableBody}
          </tbody>
        </table>
      </div>
    );

    return content;
  }

  private _getNodeQuestionResultContent() {
    let content: any[] = [];
    content.push(
      <div className="branch-configuration">
        <h3>{this.state.selection?.NAME + ' configuration: ' ?? 'Configuration'}</h3>
        <div className="field-checkbox">
          {this._getRootCheckboxContent()}
        </div>
        <div className="dropdownQuestions">
          <label htmlFor="node-question-result-value">Question:</label>
          <Dropdown
            id="node-question-result-value"
            value={this.state.selection.userData.question}
            options={this._getQuestionsByType([])}
            onChange={(e) => {
              this._setSelectionUserDataValue('question', e.value);
            }}
            filter
            optionLabel="name"
            placeholder={"Select a question"}/>
        </div>
      </div>
    );

    return content;
  }

  private _getNodeScriptContent() {
    let content: any[] = [];
    const scripts = [] as any;
    for (const script of this.state.scriptList) {
      scripts.push({ name: script.fileName, label: script.fileName });
    }
    content.push(
      <div className="branch-configuration">
        <h3>{this.state.selection?.NAME + ' configuration: ' ?? 'Configuration'}</h3>
        <div className="field-checkbox">
          {this._getRootCheckboxContent()}
        </div>
        <div className="dropdownQuestions">
          <label htmlFor="node-question-result-value">Script:</label>
          <Dropdown
            id="node-question-result-value"
            value={this.state.selection.userData.script}
            options={scripts}
            onChange={(e) => {
              this._setSelectionUserDataValue('script', e.value);
            }}
            filter
            optionLabel="name"
            placeholder={"Select a script"}/>
        </div>
      </div>
    );

    return content;
  }

  private _uuidv4() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  private _getNodeConcatContent() {
    let content: any[] = [];
    let tableBody: any[] = [];
    let nodes: any [] = [];

    for (const figure of this.state.canvas.figures.data) {
      if (figure.id === this.state.selection.id) {
        continue;
      }
      nodes.push({
        name: figure.userData.name,
        label: figure.userData.name,
        id: figure.id
      });
    }


    let i = 0;
    const selection = this.state.selection;
    for (const value of selection.userData.values) {

      let contentValueType: any = null;

      switch (value?.type?.name) {
        case 'node':
          contentValueType = (
            <div>
              <Dropdown
                id="node-question-result-value"
                value={value.target}
                options={nodes}
                onChange={(e) => {
                  for (const port of selection.hybridPorts.data) {
                    if (port.name === 'down') {
                      for (const connection of port.connections.data) {
                        if (connection.id === value.id) {
                          const command = new draw2d.command.CommandDelete(connection);
                          this.state.canvas.getCommandStack().execute(command);
                        }
                      }
                    }
                  }

                  value.target = e.value;
                  for (const figure of this.state.canvas.figures.data) {
                    if (figure.id === e.value.id) {
                      let nodeC = new TransitionConcat(selection, figure);
                      this.state.canvas.add(nodeC);
                      value.id = nodeC.id;
                    }
                  }
                  this.setState({
                    selection
                  }, () => this.setState({
                    changes: true
                  }));
                }}
                filter
                optionLabel="name"
                appendTo={document.body}
                placeholder={"Node"}/>
            </div>
          );
          break;
        case 'question':
          contentValueType = (
            <div>
              <Dropdown
                id="node-question-result-value"
                value={value.target}
                options={this._getQuestionsByType([])}
                onChange={(e) => {
                  value.target = e.target.value;
                  this.setState({
                    selection
                  }, () => this.setState({
                    changes: true
                  }));
                }}
                filter
                optionLabel="name"
                appendTo={document.body}
                placeholder={"Select a question"}/>
            </div>
          );
          break;
        case 'script':
          const scripts = [] as any;
          for (const script of this.state.scriptList) {
            scripts.push({ name: script.fileName, label: script.fileName });
          }
          contentValueType = (
            <div>
              <Dropdown
                id="node-question-result-value"
                value={value.target}
                options={scripts}
                onChange={(e) => {
                  value.target = e.target.value;
                  this.setState({
                    selection
                  }, () => this.setState({
                    changes: true
                  }));
                }}
                filter
                optionLabel="name"
                appendTo={document.body}
                placeholder={"Select a script"}/>
            </div>
          );
      }

      tableBody.push(
        <tr>
          <td>
            <div className="dropdownQuestions">
              <Dropdown
                id="node-question-result-value"
                value={value.type}
                options={
                  [
                    { name: 'node', label: 'node' },
                    { name: 'question', label: 'question' },
                    { name: 'script', label: 'script' }
                  ]
                }
                onChange={(e) => {
                  value.type = e.target.value;
                  for (const port of selection.hybridPorts.data) {
                    if (port.name === 'down') {
                      for (const connection of port.connections.data) {
                        if (connection.id === value.id) {
                          const command = new draw2d.command.CommandDelete(connection);
                          this.state.canvas.getCommandStack().execute(command);
                        }
                      }
                    }
                  }
                  value.target = null;
                  this.setState({
                    selection
                  });
                }}
                filter
                optionLabel="name"
                appendTo={document.body}
                placeholder={"Type"}/>
            </div>
          </td>
          <td>{contentValueType}</td>
          <td>
            <Button
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-outlined"
              onClick={() => {
                for (const port of selection.hybridPorts.data) {
                  if (port.name === 'down') {
                    for (const connection of port.connections.data) {
                      if (connection.id === value.id) {
                        const command = new draw2d.command.CommandDelete(connection);
                        this.state.canvas.getCommandStack().execute(command);
                      }
                    }
                  }
                }
                for (const i in selection.userData.values) {
                  if (selection.userData.values[i].uuid === value.uuid) {
                    selection.userData.values.splice(i, 1);
                  }
                }
                this.setState({
                  selection
                }, () => this.setState({
                  changes: true
                }));
              }}
            />
          </td>
        </tr>
      );
      i++;
    }

    content.push(
      <div className="branch-configuration">
        <h3>{this.state.selection?.NAME + ' configuration: ' ?? 'Configuration'}</h3>
        <div className="field-checkbox">
          {this._getRootCheckboxContent()}
        </div>
        <div className="addValueButton">
          <Button
            label="Add value"
            style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a', color: 'black' }}
            onClick={() => {
              const selection = this.state.selection;
              let maxItt = 0;
              for (const value of this.state.selection.userData.values) {
                if (value.itt > maxItt) {
                  maxItt = value.itt;
                }
              }
              selection.userData.values.push({ type: null, target: null, uuid: this._uuidv4() });
              this.setState({
                selection
              });
            }}
            className="p-button-secondary"/>
        </div>
      </div>
    );

    content.push(
      <div className="nodeConcatTableDiv">
        <table className="nodeRangeTable nodeConcatTable">
          <thead>
          <tr>
            <th>Type</th>
            <th style={{ width: '20%' }}>Target</th>
          </tr>
          </thead>
          <tbody>
          {tableBody}
          </tbody>
        </table>
      </div>
    );

    return content;
  }

  private _getNodeMinMaxContent() {
    let content: any[] = [];

    content.push(
      <div>
        <div className="branch-configuration">
          <h3>{this.state.selection?.NAME + ' configuration: ' ?? 'Configuration'}</h3>
        </div>
        <div className="field-checkbox">
          {this._getRootCheckboxContent()}
        </div>
      </div>
    );

    content.push(
      <div className="nodeMinMaxTable">
        <DataTable
          value={this.state.selection.userData.nodes}
          scrollable
          scrollHeight="200px">
          <Column
            field="nodeName"
            header="Node"
          />
        </DataTable>
      </div>
    );

    content.push(
      <div className="nodeMinMaxTable">
        <DataTable
          value={this.state.selection.userData.decisionTrees}
          scrollable
          scrollHeight="200px">
          <Column
            field="name"
            header="Decision_tree"
          />
        </DataTable>
      </div>
    );

    const values = [];
    for (const decisionTree of this.state.decisionTreeList) {
      // @ts-ignore
      values.push({ name: decisionTree.name, label: decisionTree.name });
    }
    content.push(
      <div>
        <MultiSelect
          id="node-min-decision-tree"
          value={this.state.selection.userData.decisionTrees}
          options={values}
          onChange={(e) => {
            const selection = this.state.selection;
            selection.userData.decisionTrees = e.value;
            this.setState({
              selection
            }, () => this.setState({
              changes: true
            }));
          }}
          optionLabel="label"
          placeholder="Decision_tree"
          filter
          appendTo={document.body}
        />
      </div>
    );

    content.push(
      <div className="nodeMinMaxTable">
        <DataTable
          value={this.state.selection.userData.scripts}
          scrollable
          scrollHeight="200px">
          <Column
            field="name"
            header="Script"
          />
        </DataTable>
      </div>
    );

    const scripts = [] as any;
    for (const script of this.state.scriptList) {
      scripts.push({ name: script.fileName, label: script.fileName });
    }
    content.push(
      <div>
        <MultiSelect
          id="node-min-decision-tree"
          value={this.state.selection.userData.scripts}
          options={scripts}
          onChange={(e) => {
            const selection = this.state.selection;
            selection.userData.scripts = e.value;
            this.setState({
              selection
            }, () => this.setState({
              changes: true
            }));
          }}
          optionLabel="label"
          placeholder="Script"
          filter
          appendTo={document.body}
        />
      </div>
    );

    return content;
  }

  private _getNodeComputationContent() {
    let content: any[] = [];

    let hsType = [
      { name: 'node', label: 'node' },
      { name: 'question', label: 'question' },
      { name: 'number', label: 'number' },
      { name: 'decision_tree', label: 'decision_tree' },
      { name: 'script', label: 'script' },
    ];
    let operators = [
      { name: '/', label: '/' },
      { name: '+', label: '+' },
      { name: '*', label: '*' },
      { name: '-', label: '-' },
    ];

    let nodes: any [] = [];

    for (const figure of this.state.canvas.figures.data) {
      if (figure.id === this.state.selection.id) {
        continue;
      }
      nodes.push({
        name: figure.userData.name,
        label: figure.userData.name,
        id: figure.id
      });
    }

    content.push(
      <div className="branch-configuration">
        <h3>{this.state.selection?.NAME + ' configuration: ' ?? 'Configuration'}</h3>
        <div className="field-checkbox">
          {this._getRootCheckboxContent()}
        </div>
        <p style={{ color: '#ff0000' }}>Left member</p>
        <div className="dropdownQuestions">
          <label htmlFor="node-question-result-value">Type:</label>
          <Dropdown
            id="node-question-result-value"
            value={this.state.selection.userData.lhs.type}
            options={hsType}
            onChange={(e) => {
              const selection = this.state.selection;
              selection.userData.lhs.type = e.target.value;
              this._resetNodeConnectionCompute(selection, true, false);
              this.setState({
                selection
              }, () => this.setState({
                changes: true
              }));
            }}
            filter
            optionLabel="name"
            placeholder={"Select a question"}/>
        </div>
      </div>
    );

    switch (this.state.selection.userData.lhs.type?.name) {
      case 'number':
        content.push(
          <div>
            <label htmlFor="node-question-result-value">Value:</label>
            <InputText type="number"
                       className="p-inputtext-sm number block mb-2"
                       placeholder="Enter value(s)"
                       value={parseInt(this.state.selection.userData.lhs.value)}
                       onChange={(e: any) => {
                         const selection = this.state.selection;
                         selection.userData.lhs.value = parseInt(e.target.value);
                         this.setState({
                           selection
                         }, () => this.setState({
                           changes: true
                         }));
                       }}
            />
          </div>
        );
        break;
      case 'question':
        content.push(
          <div>
            <label htmlFor="node-question-result-value">Value:</label>
            <Dropdown
              id="node-question-result-value"
              value={this.state.selection.userData.lhs.value}
              options={this._getQuestionsByType([])}
              onChange={(e) => {
                const selection = this.state.selection;
                selection.userData.lhs.value = e.value;
                this.setState({
                  selection
                }, () => this.setState({
                  changes: true
                }));
                //this._setSelectionUserDataValue('question', e.value);
              }}
              filter
              optionLabel="name"
              placeholder={"Select a question"}/>
          </div>
        );
        break;
      case 'node':
        content.push(
          <div>
            <label htmlFor="node-question-result-value">Value:</label>
            <Dropdown
              id="node-question-result-value"
              value={this.state.selection.userData.lhs.value}
              options={nodes}
              onChange={(e) => {
                const selection = this.state.selection;

                this._resetNodeConnectionCompute(selection, true, false);

                selection.userData.lhs.value = e.value;

                for (const figure of this.state.canvas.figures.data) {
                  if (figure.id === e.value.id) {
                    let tLHS = new TransitionLHS(selection, figure);
                    this.state.canvas.add(tLHS);
                    selection.userData.lhs.id = tLHS.id;
                  }
                }
                this.setState({
                  selection
                }, () => this.setState({
                  changes: true
                }));
              }}
              filter
              optionLabel="name"
              placeholder={"Select a node"}/>
          </div>
        );
        break;
      case 'decision_tree':
        content.push(
          <div>
            <label htmlFor="node-question-result-value">Decision_tree:</label>
            <Dropdown
              id="node-question-result-value"
              value={this.state.selection.userData.lhs.value}
              options={this.state.decisionTreeList}
              onChange={(e) => {
                const selection = this.state.selection;
                selection.userData.lhs.value = e.value;
                this.setState({
                  selection
                }, () => this.setState({
                  changes: true
                }));
              }}
              filter
              optionLabel="name"
              placeholder={"Select a decision_tree"}/>
          </div>
        );
        break;
      case 'script':
        const scripts = [] as any;
        for (const script of this.state.scriptList) {
          scripts.push({ name: script.fileName, label: script.fileName });
        }
        content.push(
          <div>
            <label htmlFor="node-question-result-value">Script:</label>
            <Dropdown
              id="node-question-result-value"
              value={this.state.selection.userData.lhs.value}
              options={scripts}
              onChange={(e) => {
                const selection = this.state.selection;
                selection.userData.lhs.value = e.value;
                this.setState({
                  selection
                }, () => this.setState({
                  changes: true
                }));
              }}
              filter
              optionLabel="name"
              placeholder={"Select a script"}/>
          </div>
        );
        break;
    }

    content.push(
      <div className="dropdownOperator">
        <label htmlFor="node-computation-operator"><b>Operator:</b></label>
        <Dropdown
          id="node-computation-operator"
          value={this.state.selection.userData.operator}
          options={operators}
          onChange={(e) => {
            const selection = this.state.selection;
            selection.userData.operator = e.target.value;

            const shape = this.canvas.getFigure(selection.id.toString());
            selection.setLabel(this._getComputationName(e.target.value.name) ?? shape.userData.name);

            this.setState({
              selection
            }, () => this.setState({
              changes: true
            }));
          }}
          filter
          optionLabel="name"
          placeholder={"Select a question"}/>
      </div>
    );

    content.push(
      <div>
        <p style={{ color: '#6cc93a' }}>Right member</p>
        <div className="dropdownQuestions">
          <label htmlFor="node-question-result-value">Type:</label>
          <Dropdown
            id="node-question-result-value"
            value={this.state.selection.userData.rhs.type}
            options={hsType}
            onChange={(e) => {
              const selection = this.state.selection;
              selection.userData.rhs.type = e.target.value;
              this._resetNodeConnectionCompute(selection, false, true);
              this.setState({
                selection
              }, () => this.setState({
                changes: true
              }));
            }}
            filter
            optionLabel="name"
            placeholder={"Select a question"}/>
        </div>
      </div>
    );

    switch (this.state.selection.userData.rhs.type?.name) {
      case 'number':
        content.push(
          <InputText type="number"
                     className="p-inputtext-sm block mb-2"
                     placeholder="Enter value(s)"
                     value={parseInt(this.state.selection.userData.rhs.value)}
                     onChange={(e: any) => {
                       const selection = this.state.selection;
                       selection.userData.rhs.value = parseInt(e.target.value);
                       this.setState({
                         selection
                       }, () => this.setState({
                         changes: true
                       }));
                     }}
          />
        );
        break;
      case 'question':
        content.push(
          <div>
            <label htmlFor="node-question-result-value">Value:</label>
            <Dropdown
              id="node-question-result-value"
              value={this.state.selection.userData.rhs.value}
              options={this._getQuestionsByType([])}
              onChange={(e) => {
                const selection = this.state.selection;
                selection.userData.rhs.value = e.value;
                this.setState({
                  selection
                }, () => this.setState({
                  changes: true
                }));
              }}
              filter
              optionLabel="name"
              placeholder={"Select a question"}/>
          </div>
        );
        break;
      case 'node':
        content.push(
          <div>
            <label htmlFor="node-question-result-value">Value:</label>
            <Dropdown
              id="node-question-result-value"
              value={this.state.selection.userData.rhs.value}
              options={nodes}
              onChange={(e) => {

                const selection = this.state.selection;

                this._resetNodeConnectionCompute(selection, false, true);

                selection.userData.rhs.value = e.value;

                for (const figure of this.state.canvas.figures.data) {
                  if (figure.id === e.value.id) {
                    let tRHS = new TransitionRHS(selection, figure);
                    this.state.canvas.add(tRHS);
                    selection.userData.rhs.id = tRHS.id;
                  }
                }
                this.setState({
                  selection
                }, () => this.setState({
                  changes: true
                }));
              }}
              filter
              optionLabel="name"
              placeholder={"Select a node"}/>
          </div>
        );
        break;
      case 'decision_tree':
        content.push(
          <div>
            <label htmlFor="node-question-result-value">Decision_tree:</label>
            <Dropdown
              id="node-question-result-value"
              value={this.state.selection.userData.rhs.value}
              options={this.state.decisionTreeList}
              onChange={(e) => {
                const selection = this.state.selection;
                selection.userData.rhs.value = e.value;
                this.setState({
                  selection
                }, () => this.setState({
                  changes: true
                }));
              }}
              filter
              optionLabel="name"
              placeholder={"Select a decision_tree"}/>
          </div>
        );
        break;
      case 'script':
        const scripts = [] as any;
        for (const script of this.state.scriptList) {
          scripts.push({ name: script.fileName, label: script.fileName });
        }
        content.push(
          <div>
            <label htmlFor="node-question-result-value">Script:</label>
            <Dropdown
              id="node-question-result-value"
              value={this.state.selection.userData.rhs.value}
              options={scripts}
              onChange={(e) => {
                const selection = this.state.selection;
                selection.userData.rhs.value = e.value;
                this.setState({
                  selection
                }, () => this.setState({
                  changes: true
                }));
              }}
              filter
              optionLabel="name"
              placeholder={"Select a script"}/>
          </div>
        );
        break;
    }

    return content;
  }

  private _resetNodeConnectionCompute(selection: any, lhs: boolean, rhs: boolean) {
    for (const port of selection.hybridPorts.data) {
      if (port.name === 'down') {
        for (const connection of port.connections.data) {
          if (lhs) {
            if (connection.NAME === 'lhs') {
              const command = new draw2d.command.CommandDelete(connection);
              this.state.canvas.getCommandStack().execute(command);
              selection.userData.lhs.value = null;
            }
          }
          if (rhs) {
            if (connection.NAME === 'rhs') {
              const command = new draw2d.command.CommandDelete(connection);
              this.state.canvas.getCommandStack().execute(command);
              selection.userData.rhs.value = null;
            }
          }
        }
      }
    }
    this.setState({
      selection
    }, () => this.setState({
      changes: true
    }));
  }

  private _getConfigurationContent() {
    if (this.state.selection) {
      switch (this.state.selection.NAME) {
        case 'node_answer_list' :
          return this._getNodeAnswerListContent();
        case 'node_answer_bool' :
          return this._getNodeAnswerBoolContent();
        case 'node_result' :
          return this._getNodeResultContent();
        case 'node_range' :
          return this._getNodeRangeContent();
        case 'node_question_result' :
          return this._getNodeQuestionResultContent();
        case 'node_min' :
          return this._getNodeMinMaxContent();
        case 'node_max' :
          return this._getNodeMinMaxContent();
        case 'node_computation' :
          return (<div className="nodeComputation">{this._getNodeComputationContent()}</div>);
        case 'node_script' :
          return this._getNodeScriptContent();
        case 'node_concat' :
          return this._getNodeConcatContent();
        case 'node_multiple_answer_list' :
          return this._getNodeMultipleAnswerListContent();
      }
    }
  }

  render() {

    let editPanel;

    if (this.state.showEditPanel) {
      editPanel = (
        <div id="edit-panel">
          <div className="title">
            <h4>Node edition</h4>
            <div className="delete" onClick={() => {
              this._deleteShape();
            }}>
              <i className="pi pi-trash"/>
            </div>
          </div>
          <div>
            <div className="name-field">
              <label htmlFor="node-name">Node name:</label>
              <InputText
                id="node-name"
                value={this.state.currentName ?? this.state.selection?.userData?.name ?? this._getShapeText(this.state.selectedShape)}
                onChange={(e: any) => {
                  this.setState({
                    changes: true
                  });
                  const sel = this.state.selection;
                  sel.userData.name = e.target.value;
                  if (sel.NAME === 'Transition') {
                    const line = this.canvas.getLine(sel.id.toString());
                    this._updateLineName(line, e.target.value);
                    this.setState({
                      selection: sel,
                      currentName: e.target.value
                    });
                  }
                  else {
                    const shape = this.canvas.getFigure(sel.id.toString());
                    this._updateFigureName(shape, e.target.value);
                    this.setState({
                      selection: sel,
                      currentName: e.target.value
                    });
                  }
                }}
              />
              {
                this.state.selection?.NAME === NODE_LIST.nodeRange.name ||
                this.state.selection?.NAME === NODE_LIST.nodeConcat.name ||
                this.state.selection?.NAME === NODE_LIST.nodeComputation.name
                  ?
                  (
                    <div className="informationsBox">
                      <p className="notes">
                        Links from this type of node can be created only from this panel and not directly in the diagram.
                      </p>
                    </div>
                  ) : null
              }
            </div>
            <div className="node-configuration">
              {this._getConfigurationContent()}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="App">
        <Toast ref={(el) => (this.toast = el)}/>
        <div className="topLigne"/>
        <div id="toolbar">
          <Topbar
            view={this.state.canvas}
            schema={this.state.schema}
            changes={this.state.changes}
            setChanges={(changes) => this.setState({ changes })}
            refreshSchemas={(newSchemaName: string, key: string) => {
              this._refreshSchemas(newSchemaName, key);
            }}
            hideSidebar={() => {
              let element = document.getElementsByClassName('sideBar') as any;
              if (element) {
                element[0].style.zIndex = 1;
              }
            }}
            resetSidebar={() => {
              let element = document.getElementsByClassName('sideBar') as any;
              if (element) {
                element[0].style.zIndex = 998;
              }
            }}
            currenToolsName={this.state.currentToolsName}
            setCurrentToolsName={currentToolsName => this.setState({ currentToolsName })}
            canvas={this.state.canvas}
            changeSchemaName={(name) => {
              const schema = this.state.schema as any;
              if (schema) {
                schema.name = name;
                this.setState({
                  changes: true,
                  schema
                });
              }
            }}
            refreshSchemasList={() => {
              this.setState({
                refreshSchemasList: true
              }, () => {
                this.setState({
                  refreshSchemasList: false
                });
              });
            }}
            lockSelect={(lockSelect: boolean) => {
              if (lockSelect) {
                this.state.canvas.installEditPolicy(new draw2d.policy.canvas.ReadOnlySelectionPolicy());
              }
              else {
                this.state.canvas.uninstallEditPolicy(new draw2d.policy.canvas.ReadOnlySelectionPolicy());
                this.state.canvas.installEditPolicy(new draw2d.policy.canvas.BoundingboxSelectionPolicy());
              }
            }}
          />
        </div>
        <div className="sideBar">
          <Sidebar
            view={this.state.canvas}
            schema={this.state.schema}
            setChanges={(changes) => this.setState({ changes })}
            refreshSchemas={this.state.refreshSchemasList}
            resetRefreshSchemas={() => this.setState({ refreshSchemasList: false })}
            changes={this.state.changes}
            onClick={(schema: Schema) => {
              this._loadSchema(schema);
              window.scroll(0, 0);
            }}
            newTree={() => {
              this.setState({
                showNewTreeDialog: true
              });
            }}
            currentSchemaName={this.state.currentName}
            currentKeyName={this.state.currentKey}
            resetChanges={() => this.setState({ changes: false })}
            setCurrentToolsName={(currentToolsName) => this.setState({
              currentToolsName
            })}
          />
        </div>

        <div id="canvas" style={!this.state.schema ? { display: 'none' } : {}}/>
        {editPanel}

        <Dialog
          header="Add new decision tree"
          footer={this._renderDialogFooter()}
          visible={this.state.showNewTreeDialog}
          style={{ width: '500px' }}
          onHide={() => {
            this.setState({
              showNewTreeDialog: false
            });
          }}
        >
          <div className="newTreeForm">
            <div>
              <label htmlFor="name">
                Name
              </label>
              <InputText
                id="name"
                className="p-inputtext-sm block mb-2"
                value={this.state.currentName}
                onChange={(e: any) => {
                  this.setState({
                    currentName: e.target.value
                  });
                }}
              />
            </div>
            <div>
              <label htmlFor="key">
                Key*
              </label>
              <InputText
                id="key"
                className="p-inputtext-sm block mb-2"
                value={this.state.currentKey}
                onChange={(e: any) => {
                  this.setState({
                    currentKey: e.target.value
                  });
                }}
              />
              <small id="username1-help" className="p-d-block">*This element cannot be changed.</small>
            </div>

          </div>
        </Dialog>


      </div>
    );
  }
}

export default withTranslation()(withRouter(Canvas));
