import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { TFunction } from "i18next";
import { FormService } from "../../../form/services/FormService";
import { Toast } from "primereact/toast";
import "./RequirementSidebar.scss";
import { Button } from "primereact/components/button/Button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Column } from "primereact/components/column/Column";
import { RichText } from "prismic-reactjs";

import { AxiosResponse } from "axios";
import { FileUtils } from "../../../shared/utils/FileUtils";
import { SeverityEnum } from "../../../shared/enum/SeverityEnum";
import { Toolbar } from "primereact/toolbar";
import { FileUpload } from "primereact/fileupload";
import { Requirement } from "../../models/Requirement";
import { RequirementsService } from "../../services/RequirementsService";
import { Requirements } from "../../models/Requirements";


interface States {
  requirements: Requirement[];
  isRequirementsRefreshing: boolean;
  displayConfirmation: boolean;
  toDelete: string | null;
  loading: boolean;
  selectedRequirement: any;
  displayNewRequirementConfirmation: boolean;
}

interface Props extends WithTranslation {
  requirementToViewCallback: any;
  deletedRequirement: any;
  reloadRequirements: boolean;
  resetRefreshRequirementsState: any;
  deleteRequirementAction: boolean;
  resetDeleteRequirementAction: () => void;
  actionDownloadRequirement: boolean;
  setCurrentRequirement: (currentRequirement) => void;
}

class RequirementSidebar extends React.Component<Props, States> {

  private t: TFunction;
  private formService: FormService;
  private toast: Toast | null;
  private fileUpload: FileUpload | null;
  private readonly requirementService: RequirementsService;

  constructor(props: Props) {
    super(props);
    this.formService = new FormService();
    this.requirementService = new RequirementsService();
    this.t = this.props.t;
    this.toast = null;
    this.fileUpload = null;

    this.state = {
      requirements: [],
      isRequirementsRefreshing: false,
      displayConfirmation: false,
      toDelete: null,
      loading: false,
      selectedRequirement: null,
      displayNewRequirementConfirmation: false,
    };
  }


  componentDidMount() {
    this.getRequirements();
  }

  componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any) {
    if (nextProps.reloadRequirements) {
      this.setState({
        isRequirementsRefreshing: true
      }, () => {
        this.props.resetRefreshRequirementsState();
      });
      this.getRequirements();
    }
    if (nextProps.deleteRequirementAction) {
      this.setState({ displayConfirmation: true, toDelete: this.state.selectedRequirement.requirement });
    }
    if (nextProps.actionDownloadRequirement) {
      this.handleDownload();
    }
  }


  /**
   * On download
   */
  handleDownload() {

    this.requirementService.downloadRequirements().then((response: AxiosResponse<Blob>) => {
      FileUtils.downloadFile(response);
    });
  }

  /**
   * On upload
   */
  handleUpload(e: { files: File[] }) {
    if (!e.files) {
      return;
    }

    this.requirementService
      .uploadRequirements(e.files[0])
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: `Success - Form updated`,
        });
        this.fileUpload?.clear();
        this.getRequirements();
        this.resetEditor();

      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `Error - Form NOT updated : ${message}`,
        });
        this.fileUpload?.clear();
      });
  }

  /**
   * New script
   */
  handleNewScript() {
    this.setState({ displayNewRequirementConfirmation: false, selectedRequirement: null });
    this.resetEditor();
  }

  /**
   * Delete script
   */
  handleDelete() {

    if (!this.state.toDelete) {
      return;
    }
    let requirements = [...this.state.requirements];
    /* let requirementsProperty = [...requirements[0].requirements]; */


    requirements = requirements.filter((obj) => {
      return obj.requirement !== this.state.toDelete;

    });

    this.setState({ requirements }, () => {
      this.handleUploadObject();
    });
    this.props.resetDeleteRequirementAction();
    this.setState({ displayConfirmation: false, toDelete: null, selectedRequirement: null });
  }

  handleUploadObject() {
    const dtoRequi = new Requirements(
      'default',
      this.state.requirements
    );

    const jsonString = JSON.stringify(dtoRequi);
    let f = new File([jsonString], "data.json", { type: "application/json" });

    this.requirementService
      .uploadRequirements(f)
      .then(() => {
        this.toast?.show({
          severity: SeverityEnum.SUCCESS,
          detail: `Success - Form updated`,
        });
        this.fileUpload?.clear();
        this.getRequirements();
        this.resetEditor();
      })
      .catch((err: any) => {
        const message = err?.response?.data?.message;
        this.toast?.show({
          severity: SeverityEnum.ERROR,
          detail: `Error - Form NOT updated : ${message}`,
        });
      });
  }


  resetEditor() {
    this.props.deletedRequirement();
  }

  /**
   * Load requirement
   */
  getRequirements() {
    this.requirementService
      .getMany()
      .then((response: AxiosResponse<Requirements[]>) => {
        this.setState({ requirements: response.data[0].requirements });
      });
  }

  actionBodyTemplate() {
    return (
      <React.Fragment>
        <Button
          type="button"
          tooltip="Create new requirement"
          /* label="New script"  */
          icon="pi pi-plus"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
          className="p-button-success"
          onClick={() =>
            this.setState({ displayNewRequirementConfirmation: true })
            //this.props.scriptToViewCallback(this.state.selectedRequirement.fileName);

          }
        />
        <FileUpload
          chooseLabel="Upload"
          uploadLabel="Ready to upload"
          mode="basic"
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          ref={(el) => (this.fileUpload = el)}
          accept=".json"
          customUpload={true}
          /* multiple={true} */
          uploadHandler={(e: { files: File[] }) => this.handleUpload(e)}
          auto
          // emptyTemplate={() => <p className="p-m-0">{this.t("ui_admin_html_upload_content")}</p>}
        />
      </React.Fragment>
    );
  }

  renderDialogFooter() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => {
            this.props.resetDeleteRequirementAction();
            this.setState({ displayConfirmation: false });
          }}
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          className="p-button-danger"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
          className="p-button-danger"
          onClick={() => this.handleDelete()}
          autoFocus
        />
      </div>
    );
  }

  /*
    Validate methods
  */
  validationWindowForNewScript() {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          style={{ backgroundColor: '#0087b7', borderColor: '#0087b7' }}
          onClick={() => this.setState({ displayNewRequirementConfirmation: false })}
          className="p-button-danger"
        />
        <Button
          label="Yes"
          className="p-button-danger"
          style={{ backgroundColor: '#e98b3a', borderColor: '#e98b3a' }}
          icon="pi pi-check"
          onClick={() => this.handleNewScript()}
          autoFocus
        />
      </div>
    );
  }

  render() {
    return (
      <>
        <Toast ref={(el) => this.toast = el}/>
        <DataTable
          value={this.state.requirements}
          tableClassName="table-custom"
          scrollable
          scrollHeight="40vh"
          selection={this.state.selectedRequirement}
          onSelectionChange={e => {
            this.props.setCurrentRequirement(e.value);
            this.setState({ selectedRequirement: e.value }, () => {
              if (this.state.selectedRequirement) {
                this.props.requirementToViewCallback(this.state.selectedRequirement);
              }
            });
          }}
          onRowReorder={((e: any) => {
            this.setState({
              requirements: e.value
            }, () => {
              this.handleUploadObject();
            });
          })}
          dataKey="requirement"
          stateStorage="local"
          selectionMode="single"
          reorderableColumns
        >
          <Column
            field="requirement"
            filterPlaceholder={RichText.asText(this.t(
              "ui_admin_script_search_by_name",
              { returnObjects: true }
            ))}
            style={{ width: '50px' }}
            header="List of requirements"
            /* title="the message" */
          />
          <Column
            rowReorder
            style={{ width: '15px' }}
            rowReorderIcon="pi pi-ellipsis-v"
          />
        </DataTable>

        <Toolbar
          left={() =>
            this.actionBodyTemplate()
          }
        />
        <Dialog
          header="Confirmation"
          visible={this.state.displayConfirmation}
          modal
          style={{ width: "350px" }}
          footer={this.renderDialogFooter()}
          onHide={() => this.setState({ displayConfirmation: false }, () => this.props.resetDeleteRequirementAction())}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>Are you sure you want to proceed?</span>
          </div>
        </Dialog>


        <div>
          <Dialog
            header="Confirmation"
            visible={this.state.displayNewRequirementConfirmation}
            modal
            style={{ width: "350px" }}
            footer={this.validationWindowForNewScript()}
            onHide={() => this.setState({ displayNewRequirementConfirmation: false })}
          >
            <div className="confirmation-content">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              <span>Are you sure you want to create a new requirement?</span>

            </div>
          </Dialog>
        </div>
      </>
    );
  }
}

export default withTranslation()(RequirementSidebar);
